import React, {FunctionComponent, useEffect, useState} from "react";
import "./LoadingGif.scss";
import {ReactComponent as LogoSmall} from "../../assets/logo-small.svg";

type LoadingGifProps = {
  errorText?: JSX.Element,
  passedInText?: JSX.Element,
  overrideLoadingText?: string,
}

const LoadingGif: FunctionComponent<LoadingGifProps> = (loadingGifProps) => {
  const {errorText, passedInText, overrideLoadingText} = loadingGifProps;
  const [loadingText, setLoadingText] = useState<string>(overrideLoadingText || "Loading...");

  useEffect(() => {

  }, []);

  function getLoadingText() {
    if (errorText) {
      return <div className="center-text padding-top"
                  style={{marginBottom: "auto"}}>
        {errorText}
      </div>
    } else if (passedInText) {
      return <div className="center-text padding-top"
                  style={{marginBottom: "auto"}}>
        {passedInText}
      </div>
    } else {
      return <h5 className="loading-text center-text max-small-text-width">{loadingText}</h5>;
    }
  }

  return (
    <div className="loading-container flex-col">
      <LogoSmall id="loading-logo-id" className="loading-logo"/>
      {getLoadingText()}
    </div>
  )
}

export default LoadingGif;