import React, {FunctionComponent, useEffect} from "react";
import {StyledNegativeButton} from "../../style/MaterialUIStyling";
import {CustomButtonProps} from "./CustomButtonsUtils";

const NegativeButton: FunctionComponent<CustomButtonProps<any>> = (negativeButtonProps) => {
  const {
    children,
    disabled,
    className,
    endIcon,
    onClick,
    id,
    startIcon,
    style,
    variant
  } = negativeButtonProps;

  useEffect(() => {

  }, []);

  return (
    <div>
      {/* @ts-ignore Server Component */}
      <StyledNegativeButton
        id={id}
        color="default"
        className={className}
        disabled={disabled}
        endIcon={endIcon}
        onClick={onClick}
        startIcon={startIcon}
        style={style}
        variant={variant ? variant : "contained"}

      >
        {children}
      </StyledNegativeButton>
    </div>
  );
}

export default NegativeButton;