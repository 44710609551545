import React, {FunctionComponent, useEffect, useState} from "react";
import {Auth} from "aws-amplify";
import {fetchPostBody} from "../../../utils/fetchUtils";
import MessageBox from "../../../components/MessageBox/MessageBox";
import Body1 from "../../../components/Typography/Body1";
import OurDivider from "../../../components/OurDividers/OurDivider";
import IconButton from "@material-ui/core/IconButton";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import OpenInNewOutlinedIcon from "@material-ui/icons/OpenInNewOutlined";
import {toast} from "react-toastify";
import OurToastContainer from "../../../components/OurToastContainer/OurToastContainer";
import {useSearchParams} from "react-router-dom";
import {ApplyingUser} from "../MarketplaceTool/MarketplaceTool";
import usePagination from "../../../components/Pagination/Pagination";
import LoadingGif from "../../../components/LoadingGif/LoadingGif";
import {Pagination} from "@material-ui/lab";
import CopyText from "../../../components/CopyText/CopyText";
import UlLink from "../../../components/UlLink/UlLink";
import Caption from "../../../components/Typography/Caption";

type AcceptedUsers = {
  username: string,
  accept_timestamp: string,
}

const MarketplaceAcceptToastContainerId = "marketplaceAcceptToastContainerId";
const MarketplaceToolToastErrorId = "MarketplaceToolToastErrorId";
const MarketplaceToolToastSuccessId = "MarketplaceToolToastSuccessId";

const usersPerPage = 30;

const MarketplaceAccept: FunctionComponent<{}> = () => {
  const [searchParams, setSearchParam] = useSearchParams();

  const [currPageNumber, setCurrPageNumber] = useState<number>((parseInt(searchParams.get("page") || "1")));
  const [acceptedUsernames, setAcceptedUsernames] = useState<AcceptedUsers[] | undefined>(undefined);
  const [hydratedPageData, setHydratedPageData] = useState<Array<ApplyingUser>>([]);

  const pageData = usePagination(acceptedUsernames, usersPerPage, currPageNumber, setCurrPageNumber);

  const notifyError = (message) => toast(message, {type: "error", pauseOnHover: true, toastId: MarketplaceToolToastErrorId, containerId: MarketplaceAcceptToastContainerId});
  const notifySuccess = (message) => toast(message, {type: "success", pauseOnHover: true, toastId: MarketplaceToolToastSuccessId, containerId: MarketplaceAcceptToastContainerId});

  useEffect(() => {
    getAcceptedUsers();
  }, [])

  useEffect(() => {
    if (pageData.currentData.length > 0) {
      Auth.currentAuthenticatedUser({bypassCache: false})
        .then(currUser => {
          return fetchPostBody<Array<ApplyingUser>>("admin/action", {
            "action": "getUsersInfo",
            // @ts-ignore
            "authIds": pageData.currentData.map((user) => user.username),
          }, currUser.getSignInUserSession()?.getIdToken().getJwtToken())
            .then((res) => {
              setHydratedPageData(res);
            })
        })
    }
  }, [pageData.currentData])

  useEffect(() => {
    if (currPageNumber === 1) {
      searchParams.set("page", "1");
      setSearchParam(searchParams);
    }
  }, [currPageNumber])

  function getAcceptedUsers() {
    Auth.currentAuthenticatedUser({bypassCache: false})
      .then(currUser => {
        return fetchPostBody("admin/action", {"action": "getAcceptedUsers"}, currUser.getSignInUserSession()?.getIdToken().getJwtToken())
          .then((res) => {

            if (res === undefined || res === null) {
              setAcceptedUsernames([]);
              return;
            }

            const usernames: AcceptedUsers[] = [];
            // @ts-ignore
            Object.entries(res).forEach(([key, value]) => {
              // @ts-ignore
              usernames.push({username: key, accept_timestamp: value["accept_timestamp"]})
            })
            setAcceptedUsernames(usernames);
          })

      })
  }

  function copyText(text) {
    navigator.clipboard.writeText(text)
      .then(() => {
        notifySuccess("Copied to clipboard!")
      })
      .catch(() => {
        notifyError("Something went wrong")
      })
  }

  return <>
    <MessageBox text="Marketplace Accepted Users"/>
    <div className="laptop-screen-width-padding padding-inline-screen">
      {acceptedUsernames === undefined && <LoadingGif/>}
      {acceptedUsernames?.length === 0 && <div className="padding-block-self center-text">
        <h5>No accepted users</h5>
      </div>}
      {hydratedPageData.length > 0 ? hydratedPageData.map((acceptedUser) => <>
          <div className="flex-row-half-gap">
            <div className="flex-row-half-gap">
              <div className="flex-col-no-align">
                <h6 className="bold">{acceptedUser.username}</h6>
                {acceptedUsernames!.find((user) => user.username === acceptedUser.username)?.accept_timestamp}
                <div className="padding-top"/>
                {acceptedUser.socialMediaLink && <CopyText copyText={acceptedUser.socialMediaLink}>
                  <Caption className="padding-block-self">
                    <UlLink link={acceptedUser.socialMediaLink} newTab={true}/>
                  </Caption>
                </CopyText>}
              </div>
              <div style={{alignSelf: "flex-start"}}>
                <IconButton onClick={() => copyText(acceptedUser.username)}
                            size="medium">
                  <FileCopyOutlinedIcon className={"copy-icon pointer clr-grey"}/>
                </IconButton>
                <IconButton onClick={() => window.open(`https://artistree.io/${acceptedUser.username}`, "_blank")}
                            size="medium">
                  <OpenInNewOutlinedIcon className={"copy-icon pointer clr-grey"}/>
                </IconButton>
              </div>
            </div>
            {acceptedUser.profilePic && <CopyText copyText={acceptedUser.profilePic}>
              <Body1 className="padding-block-self">
                <UlLink link={acceptedUser.profilePic} text={"Profile pic"} newTab={true}/>
              </Body1>
            </CopyText>}
          </div>
          <OurDivider margin="dense"/>
        </>
      ) : acceptedUsernames?.length !== 0 ? <LoadingGif/> : <MessageBox text={"No users to add, that's all folks!"}/>}
    </div>

    <div className="padding-self"/>
    <div className="flex-row-gap">
      {acceptedUsernames !== undefined && <Pagination count={Math.ceil(acceptedUsernames.length / usersPerPage)}
                                                      size="large"
                                                      page={currPageNumber}
                                                      variant="outlined"
                                                      shape="rounded"
                                                      onChange={(event, page) => {
                                                        setHydratedPageData([])
                                                        setCurrPageNumber(page);
                                                        searchParams.set("page", page.toString());
                                                        setSearchParam(searchParams);
                                                        pageData.jumpToPage(page);
                                                      }}
                                                      style={{marginInline: "auto"}}
      />}
    </div>
    <OurToastContainer containerId={MarketplaceAcceptToastContainerId}/>
  </>;
}

export default MarketplaceAccept;