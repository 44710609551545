import {OnboardingStatus} from "../contexts/SignupOnboardingContext/OnboardingProvider";
import {CognitoUser} from "amazon-cognito-identity-js";
import {fetchPostBody} from "./fetchUtils";

export function isFullyOnboarded(onboardingStatus: OnboardingStatus): boolean {
  const {commissionTypesFilled, paymentOnboarded} = onboardingStatus;
  return commissionTypesFilled === true && paymentOnboarded === true
}

export function setUserToActive(user: CognitoUser): Promise<void> {
  return fetchPostBody("signup/profile", {
    "setActive": true
  }, user.getSignInUserSession()?.getIdToken().getJwtToken())
}