import React, {CSSProperties, FunctionComponent, useEffect} from "react";

type CaptionProps = {
  className?: string,
  id?: string,
  title?: string,
  style?: CSSProperties
}


const Caption: FunctionComponent<CaptionProps> = (props) => {
  const {className, id, title, style} = props;

  useEffect(() => {

  }, []);

  return (
    <p id={id} title={title} className={"caption " + className} style={style}>
      {props.children}
    </p>
  )
}

export default Caption;