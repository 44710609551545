import React, {FunctionComponent, useEffect, useState} from "react";
import Divider from "@material-ui/core/Divider";
import Caption from "../Typography/Caption";
import Body2 from "../Typography/Body2";

type OurDividerProps = {
  margin?: "normal" | "dense" | "none";
  inlinedLabel?: string;
};

const OurDivider: FunctionComponent<OurDividerProps> = (ourDividerProps) => {
  const {
    margin,
    inlinedLabel
  } = ourDividerProps;

  const [paddingClassName, setPaddingClassName] = useState<string | undefined>("padding-block-self");

  useEffect(() => {

  }, []);

  useEffect(() => {
    if (margin === "dense") {
      setPaddingClassName("padding-bottom");
    } else if (margin === "none") {
      setPaddingClassName(undefined);
    }
  }, [margin]);

  return (
    <div>
      <div className={paddingClassName}/>
      {inlinedLabel ?
        <div className="flex-row">
          <Divider variant="fullWidth" style={{flexGrow: "1"}}/>
          <Body2 className="clr-grey" style={{paddingRight: "0.35rem", paddingLeft: "0.35rem"}}>{inlinedLabel}</Body2>
          <Divider variant="fullWidth" style={{flexGrow: "1"}}/>
        </div>
        : <Divider variant="fullWidth"/>}
      <div className={paddingClassName}/>
    </div>
  )
}

export default OurDivider;