import React, {createContext, FunctionComponent, useContext, useEffect} from "react";
import {useLocalStorage} from "../../utils/localStorageUtils";

export const LIGHT_THEME = "light";
export const DARK_THEME = "dark";

export type ThemePreferences = "light" | "dark";

type ThemePreferenceContextType = {
  themePreference: ThemePreferences,
  toggleTheme: () => void,
  isLightTheme: () => boolean,
  isDarkTheme: () => boolean,
}

const ThemePreferenceContext = createContext<ThemePreferenceContextType>({
  themePreference: LIGHT_THEME,
  toggleTheme: () => {},
  isLightTheme: () => true,
  isDarkTheme: () => false,
});

const useThemePreference = () => useContext(ThemePreferenceContext);

const ThemePreferenceProvider: FunctionComponent<{}> = ({children}) => {
  // Hi there 👋, thank you for reading these. If, for whatever reason, you attempt to change this, please check the index.html file.
  // At the top, it has a script that checks this before the css loads. This is done so the css reflects the theme pref and screen doesn't flash white on site load.
  const [themePreference, setThemePreference] = useLocalStorage<ThemePreferences>("theme-preference", LIGHT_THEME);

  useEffect(() => {

  }, []);

  useEffect(() => {
  }, [themePreference])

  function toggleTheme() {
    const newThemePref = themePreference === LIGHT_THEME ? DARK_THEME : LIGHT_THEME;
    setThemePreference(newThemePref);

    document.firstElementChild?.setAttribute('data-theme', newThemePref);
  }

  function isLightTheme() {
    return themePreference === LIGHT_THEME;
  }

  function isDarkTheme() {
    return themePreference === DARK_THEME;
  }

  return (
    <ThemePreferenceContext.Provider value={{
      themePreference,
      toggleTheme,
      isLightTheme,
      isDarkTheme,
    }}>
      {children}
    </ThemePreferenceContext.Provider>
  )
}

export {useThemePreference, ThemePreferenceProvider}