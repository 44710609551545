import React from "react";
import {Helmet} from "react-helmet";
import homepageBackgroundOGPreview from "../assets/homepage-background-og-preview.jpg";
import homepageBackgroundTwitterPreview from "../assets/homepage-background-twitter-preview.png";

type PageType = "profile" | "blog" | "website"
type PageMetaData = {
  description: string
  image: string
  title: string
  type: PageType
  url: string,
  overrideTwitterImage?: string,
}

export const siteImgPreview: Pick<PageMetaData, "image" | "overrideTwitterImage"> = {
  image: homepageBackgroundOGPreview,
  overrideTwitterImage: homepageBackgroundTwitterPreview,
}

export function getMetaTags(pageMetaData: PageMetaData): JSX.Element {
  const {description, image, title, type, url, overrideTwitterImage} = pageMetaData;

  return <Helmet>
    <title>{title}</title>
    <meta name="description" content={description}/>
    <meta name="title" content={title}/>
    <meta name="apple-mobile-web-app-capable" content="yes"/>
    <meta name="mobile-web-app-capable" content="yes"/>
    {/*<!-- Open Graph / Facebook -->*/}
    <meta property="og:title" content={title}/>
    <meta property="og:description" content={description}/>
    <meta property="og:type" content={type}/>
    <meta property="og:image" content={image}/>
    <meta property="og:url" content={url}/>
    {/* <!-- Twitter -->*/}
    <meta property="twitter:title" content={title}/>
    <meta name="twitter:card" content="summary"/>
    <meta property="twitter:description" content={description}/>
    <meta property="twitter:url" content={url}/>
    <meta property="twitter:image" content={overrideTwitterImage ? overrideTwitterImage : image}/>
  </Helmet>
}