import React, {FunctionComponent, useEffect} from "react";
import Button from "@material-ui/core/Button";
import WbSunnyRoundedIcon from "@material-ui/icons/WbSunnyRounded";
import Brightness3RoundedIcon from "@material-ui/icons/Brightness3Rounded";
import {useThemePreference} from "../../contexts/ThemePreferenceContext/ThemePreferenceProvider";
import "./ThemeToggleButton.scss";


const ThemeToggleButton: FunctionComponent<{}> = () => {
  const {toggleTheme, isDarkTheme} = useThemePreference();

  useEffect(() => {

  }, []);

  const themeIcon = isDarkTheme() ? <WbSunnyRoundedIcon/> : <Brightness3RoundedIcon className="theme-toggle-moon-icon"/>;

  return (<div>
    <Button onClick={toggleTheme}
            startIcon={themeIcon}
            aria-label={"Toggle Theme"}
    >
      {isDarkTheme() ? "Light" : "Dark"}
    </Button>
  </div>);
}

export default ThemeToggleButton;