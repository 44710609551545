import {useEffect, useState} from "react";
import {debounce} from "lodash";

export type DisplayOptions = "block" | "none";
export const SHOW = "block";
export const HIDE = "none";

export function displayElementById(htmlId: string): void {
  getElementById(htmlId).style.display = "block";
}

export function displayElementByIdSuppressed(htmlId: string): void {
  try {
    getElementById(htmlId).style.display = "none";
  } catch (e) {
  }
}

export function displayElementByClassName(className: string): void {
  getStylesWithClassName(className).display = "block";
}

export function hideElementById(htmlId: string): void {
  getElementById(htmlId).style.display = "none";
}

export function hideElementByIdSuppressed(htmlId: string): void {
  try {
    getElementById(htmlId).style.display = "none";
  } catch (error) {
  }

}

export function hideElementByClassName(className: string): void {
  getStylesWithClassName(className).display = "none";
}

export function getComputedStylesWithClassName(elementClassName: string): CSSStyleDeclaration {
  return getComputedStyle(getFirstElementByClassName(elementClassName));
}

export function getComputedStylesById(elementId: string): CSSStyleDeclaration {
  return getComputedStyle(getElementById(elementId));
}

export function getStylesWithClassName(elementClassName: string) {
  // @ts-ignore for style, typescript is odd sometimes, I'll look into it later (ie. probably never)
  // https://stackoverflow.com/questions/37655393/how-to-set-multiple-css-style-properties-in-typescript-for-an-element
  return getFirstElementByClassName(elementClassName).style;
}

export function getElementById(elementId: string): HTMLElement {
  const element = document.getElementById(elementId);
  if (element) {
    return element
  }
  throw new Error("Element " + elementId + " could not be found");
}

export function getElementByIdSuppressed(elementId: string): HTMLElement | null {
  return document.getElementById(elementId);
}

export function getFirstElementByClassName(elementClassName: string): Element {
  const htmlElements = getElementsByClassName(elementClassName);
  if (htmlElements.length) {
    const theElement = htmlElements.item(0);
    if (theElement) {
      return theElement;
    }
  }
  throw new Error("Element " + elementClassName + " could not be found");
}

export function getElementsByClassName(elementClassName: string): HTMLCollection {
  return document.getElementsByClassName(elementClassName);
}

export function getElementByQuerySelector(query: string): Element {
  const element = document.querySelector(query);
  if (element) {
    return element;
  }

  throw new Error("Query selector " + query + " didn't find an element");
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", debounce(handleResize, 100));
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

function getWindowDimensions() {
  const {innerWidth: width, innerHeight: height} = window;
  return {
    width,
    height
  };
}

export function isOverflowingYById(id: string): boolean {
  const {clientHeight, scrollHeight} = getElementById(id);
  return scrollHeight > clientHeight;
}