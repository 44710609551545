import React, {FunctionComponent, useEffect, useState} from 'react';
import AppBar from '@material-ui/core/AppBar';
import logoLight from '../../assets/logo.svg'
import logoDark from '../../assets/logo-dark.svg'
import logoSmallLight from '../../assets/logo-small.svg'
import logoSmallDark from '../../assets/logo-small-dark.svg'
import {Link, useLocation, useNavigate} from "react-router-dom";
import Button from '@material-ui/core/Button';
import Fab from "@material-ui/core/Fab";
import Slide from "@material-ui/core/Slide";
import Zoom from "@material-ui/core/Zoom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import DashboardRoundedIcon from '@material-ui/icons/DashboardRounded';
import "./NavBar.scss"
import "../../style/Layout.scss";
import {makeStyles} from "@material-ui/core/styles";
import KeyboardArrowUpRoundedIcon from '@material-ui/icons/KeyboardArrowUpRounded';
import {getElementById} from "../../utils/displayUtils";
import {CognitoUser} from "amazon-cognito-identity-js";
import {useThemePreference} from "../../contexts/ThemePreferenceContext/ThemePreferenceProvider";
import Body1 from "../Typography/Body1";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import {useLocalStorage} from "../../utils/localStorageUtils";
import UlLink from "../UlLink/UlLink";
import {Auth} from 'aws-amplify';
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';

const announcements = [
  {
    content: <>
      <Body1>
        More customization for request forms is now <UlLink link="/profile/commission-extras" text="here"/>!
      </Body1>
    </>,
  },
  {
    content: <>
      <Body1>
        Discord notifications are now <UlLink link="/profile/notifications" text="here!"/>
      </Body1>
    </>,
  }
]


type NavBarProps = {
  currUser: CognitoUser | undefined,
  isError: boolean
}

const NavBar: FunctionComponent<NavBarProps> = (navBarProps) => {
  const {
    currUser,
    isError
  } = navBarProps;

  const {isDarkTheme} = useThemePreference();
  const [lastShownIndex, setLastShownIndex] = useLocalStorage<number>("last-shown-announcement-index", -1);

  const [navBarPosition, setNavBarPosition] = useState<"fixed" | "static">("fixed");
  const [showLatestAnnouncement, setShowLatestAnnouncement] = useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();

  const isMobileL = useMediaQuery('(min-width:510px)');

  useEffect(() => {
  }, []);

  const pathname = location.pathname;

  useEffect(() => {
    if (pathname === "/") {
      setNavBarPosition("fixed");
    } else {
      setNavBarPosition("static")
    }
  }, [location]);

  useEffect(() => {
    if (isError) {
      setNavBarPosition("static");
    }
  }, [isError]);

  useEffect(() => {
    if ((lastShownIndex < announcements.length - 1) && (pathname === "/" || pathname === "/dashboard")) {
      setShowLatestAnnouncement(true);
    } else {
      setShowLatestAnnouncement(false);
    }
  }, [lastShownIndex, location]);

  function getLoggedInNav() {
    return <>
      <Button onClick={() => navigate("/dashboard")}
              variant="text"
              startIcon={<DashboardRoundedIcon/>}>
        Dashboard
      </Button>
      <Button onClick={() => Auth.signOut()}
              startIcon={<PersonRoundedIcon/>}
              variant="text">
        Sign Out
      </Button>
    </>;
  }

  function getNotLoggedInNav() {
    return <>
      <Button variant="outlined" color="secondary" onClick={() => navigate("/sign-in")}>Log In</Button>
    </>;
  }

  const logoSrc = isDarkTheme() ? logoDark : logoLight;
  const logoSmallSrc = isDarkTheme() ? logoSmallDark : logoSmallLight;

  return (<>

    <div id="back-to-top-anchor"/>
    <Slide appear={false} direction="down" in={!useScrollTrigger({disableHysteresis: true})}>
      <AppBar position={navBarPosition} className="padding-inline-screen nav-bar">
        <div className="flex-row-gap">
          <Link to='/'>
            <img alt="Home Page" className="logo" src={logoSrc}/>
            <img alt="Home Page" className="logo-small" src={logoSmallSrc}/>
          </Link>
          <div className="flex-row-half-gap nav-bar-icons">
            {currUser ? getLoggedInNav() : getNotLoggedInNav()}
          </div>
        </div>
        {/*<Body1 className="center-text maintenance-msg">*/}
        {/*  We're doing some maintenance right now, sorry for any interruptions.*/}
        {/*</Body1>*/}
        {/*<Body2 className="padding-inline-self center-text none-selectable maintenance-msg">*/}
        {/*  We're experiencing some service outages, sorry for any inconvenience this may cause.*/}
        {/*</Body2>*/}
        {(currUser && showLatestAnnouncement) &&
          <div className="announcement-msg">
            <div className="flex-row center-text">
              <div style={{marginLeft: "auto", paddingLeft: "0.75rem"}}>
                {announcements[announcements.length - 1].content}
              </div>
              <CloseRoundedIcon className="pointer"
                                fontSize="small"
                                onClick={() => setLastShownIndex(announcements.length - 1)}
                                style={{marginLeft: "auto", marginRight: "0.75rem"}}/>
            </div>
          </div>
        }
      </AppBar>
    </Slide>
    <ScrollTop>
      <Fab color="secondary" size="small" aria-label="scroll back to top">
        <KeyboardArrowUpRoundedIcon/>
      </Fab>
    </ScrollTop>
  </>)
}

const toTopButtonStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 2,
  },
}));

function ScrollTop(props) {
  const {children} = props;
  const topButtonStyles = toTopButtonStyles();

  function handleClick(event) {
    const anchor = getElementById("back-to-top-anchor");

    if (anchor) {
      anchor.scrollIntoView({behavior: 'smooth', block: 'center'});
    }
  }

  return (
    <Zoom in={useScrollTrigger({disableHysteresis: true})}>
      <div onClick={handleClick} role="presentation" className={topButtonStyles.root}>
        {children}
      </div>
    </Zoom>
  );
}


export default NavBar;
