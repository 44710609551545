import {sendMixEvent} from "../../utils/MixPanelUtils";
import UAParser from "ua-parser-js";

const PWAInstallActions = {
  promptShown: 'PROMPT_SHOWN',
  ourPromptAccepted: 'OUR_PROMPT_ACCEPTED',
  ourPromptDismissed: 'OUR_PROMPT_DISMISSED',
  browserPromptAccepted: 'BROWSER_PROMPT_ACCEPTED',
  browserPromptDismissed: 'BROWSER_PROMPT_DISMISSED',
  appInstalled: 'APP_INSTALLED',
}


const {
  promptShown,
  ourPromptAccepted,
  ourPromptDismissed,
  browserPromptAccepted,
  browserPromptDismissed,
  appInstalled,
} = PWAInstallActions;

export class PWAInstallTracker {
  public static promptShown() {
    senPWATrackerEvent(toPwaInstallEvent(promptShown));
  }

  public static ourPromptAccepted() {
    senPWATrackerEvent(toPwaInstallEvent(ourPromptAccepted));
  }

  public static ourPromptDismissed() {
    senPWATrackerEvent(toPwaInstallEvent(ourPromptDismissed));
  }

  public static browserPromptAccepted() {
    senPWATrackerEvent(toPwaInstallEvent(browserPromptAccepted));
  }

  public static browserPromptDismissed() {
    senPWATrackerEvent(toPwaInstallEvent(browserPromptDismissed));
  }

  public static appInstalled() {
    senPWATrackerEvent(toPwaInstallEvent(appInstalled));
  }
}

function senPWATrackerEvent(event: any): void {
  sendMixEvent('PWA_INSTALL', event)
}

function toPwaInstallEvent(action: string) {
  return {
    action: action,
    userAgent: new UAParser().getUA(),
  }
}