import React, {FunctionComponent, useEffect, useState} from 'react';
import '@asseinfo/react-kanban/dist/styles.css'
import './OrderLookupTool.scss'
import '../../../App.scss'
import {fetchGetBody, fetchPostBody} from "../../../utils/fetchUtils";
import {Auth} from "aws-amplify";
import {useNavigate} from "react-router-dom";
import Body1 from "../../../components/Typography/Body1";
import AsyncActionButton from "../../../components/AsyncActionButton/AsyncActionButton";
import {toast} from "react-toastify";
import OurToastContainer from "../../../components/OurToastContainer/OurToastContainer";
import MessageBox from "../../../components/MessageBox/MessageBox";
import TextField from "@material-ui/core/TextField";
import OurDivider from "../../../components/OurDividers/OurDivider";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import {MenuItem, Select} from '@material-ui/core';
import DoneRoundedIcon from '@material-ui/icons/DoneRounded';
import IconButton from "@material-ui/core/IconButton";
import OurLoadingIcon from "../../../components/OurLoadingIcon/OurLoadingIcon";


const OrderToolToastContainerId = "OrderToolToastContainerId";
const OrderToolToastErrorId = "OrderToolToastErrorId";
const OrderToolToastSuccessId = "OrderToolToastSuccessId";

enum statuses {
  Requested = "Requested",
  AwaitingPayment = "Awaiting Payment",
  InProgress = "In Progress",
  AwaitingFinalApproval = "Awaiting Final Approval",
  Closed = "Closed",
  Cancelled = "Cancelled",
  Declined = "Declined",
  Paid = "Paid",
}

const {Cancelled, Paid} = statuses;

const OrderLookupTool: FunctionComponent<{}> = () => {
  const navigate = useNavigate();

  const [commissionInfo, setCommissionInfo] = useState<Map<string, string>>(new Map());
  const [commissionId, setCommissionId] = useState<string>("");

  const notifyError = (message) => toast(message, {type: "error", pauseOnHover: true, toastId: OrderToolToastErrorId, containerId: OrderToolToastContainerId});
  const notifySuccess = (message) => toast(message, {type: "success", pauseOnHover: true, toastId: OrderToolToastSuccessId, containerId: OrderToolToastContainerId});

  useEffect(() => {

  }, []);

  function handleSubmit() {
    return Auth.currentAuthenticatedUser({bypassCache: false})
      .then(currUser => {
        return fetchGetBody<Map<string, string>>(`admin/order?commissionId=${commissionId}`, currUser.getSignInUserSession()?.getIdToken().getJwtToken())
          .then((res) => {
            if (res === null) {
              notifyError("Order not found!");
              return;
            }
            setCommissionInfo(res);
          }).catch((err) => {
            notifyError(err);
          })
      })
  }

  const StatusChange = () => {
    const [newStatus, setNewStatus] = useState<string>("");
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    function handleStatusChange() {
      setIsSubmitting(true);
      return Auth.currentAuthenticatedUser({bypassCache: false})
        .then(currUser => {
          return fetchPostBody("admin/action", {
            "action": "changeCommissionStatus",
            "id": commissionId,
            "newStatus": newStatus,
          }, currUser.getSignInUserSession()?.getIdToken().getJwtToken())
            .then(handleSubmit)
            .then(() => notifySuccess("Updated successfully!"))
            .catch((err) => notifyError("Something went wrong, talk to Yazeed"))
        })
        .finally(() => {
          setIsSubmitting(false);
        })
    }

    const labelId = "new-status-select-label"
    return <div className="flex-row-half-gap">
      <FormControl fullWidth={true}
                   style={{maxWidth: "200px"}}
                   variant="outlined"
                   margin={"dense"}
      >
        <InputLabel id={labelId}>New Status</InputLabel>
        <Select
          labelId={labelId}
          id="demo-simple-select"
          value={newStatus}
          onChange={(event) => setNewStatus(event.target.value as string)}
        >
          {Object.values(statuses).map((status) => {
            if (status === commissionInfo["Status"]) {
              return undefined;
            }
            return <MenuItem value={status}>{status} {(status === Paid || status === Cancelled) && " - Invoice only"}</MenuItem>
          })}
        </Select>
      </FormControl>
      {isSubmitting ? <OurLoadingIcon size={20} loadVisible={true}/> :
        <IconButton color="primary"
                    disabled={newStatus.length === 0}
                    onClick={handleStatusChange}>
          <DoneRoundedIcon/>
        </IconButton>}
    </div>
  }

  return <>
    <MessageBox text="Order Lookup Tool"/>
    <div className='laptop-screen-width-padding padding-inline-screen'>
      <div className="padding-bottom max-small-text-width">
        <TextField label="commission Id"
                   onChange={(event) => setCommissionId(event.target.value)}
                   value={commissionId}
                   margin={"dense"}
                   variant={"outlined"}
                   fullWidth={true}/>
        <div className="padding-bottom"/>
        <AsyncActionButton action={handleSubmit}
                           disabled={commissionId.length > 1}
                           color={"primary"}
                           text={"Get comm data"}
                           variant={"contained"}/>
      </div>

      <div className="padding-top flex-col-no-align-gap">
        {commissionInfo && Object.entries(commissionInfo).map(([key, value]) => {
          if (value === null || value === undefined || value === "") {
            return undefined;
          }
          if (key === "Extras") {
            return <>
              <div className="flex-row-gap">
                <Body1 className="semi-bold">{key}:</Body1>
                <Body1></Body1>
              </div>
              <OurDivider margin={"dense"}/>
              {value.map((extra_value) => {
                return Object.entries(extra_value).map(([extra_key, extra_value]) => {
                  return <div className="flex-row-gap">
                    <Body1 className="semi-bold">{extra_key}:</Body1>
                    <Body1>{extra_value || []}</Body1>
                  </div>
                })
              })}
              <OurDivider margin={"dense"}/>
            </>
          }
          return <div className="flex-row-gap">
            <Body1 className="semi-bold">{key}:</Body1>
            <Body1 linkify={true}>{value}</Body1>
            {key === "Status" && <StatusChange/>}
          </div>
        })}
      </div>
      <OurToastContainer containerId={OrderToolToastContainerId}/>
    </div>
  </>;

}

export default OrderLookupTool;
