import React, {FunctionComponent, useEffect, useState} from 'react';
import UlLink from "../UlLink/UlLink";
import "./footer.scss";
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import DiscordIcon from "../../assets/discord.svg";
import {useCookiesSettings} from "../../contexts/CookieSettingsContext/CookieSettingsContext";
import lightLogo from '../../assets/logo.svg';
import darkLogo from '../../assets/logo-dark.svg';
import "../../style/Layout.scss";
import "../../style/Styling.scss";
import Body2 from "../Typography/Body2";
import {discordLink, instaramLink, twitterLink} from "../../utils/Links";
import {useThemePreference} from "../../contexts/ThemePreferenceContext/ThemePreferenceProvider";
import ThemeToggleButton from "../ThemeToggleButton/ThemeToggleButton";
import {useLocation} from "react-router-dom";
import {HIDE, SHOW} from "../../utils/displayUtils";

export const siteFooterId = "site-footer";

const Footer: FunctionComponent<{}> = () => {
  const [showThemeToggle, setShowThemeToggle] = useState<boolean>(true);

  const {openCookieModal} = useCookiesSettings();
  const {isDarkTheme} = useThemePreference();
  const location = useLocation();

  useEffect(() => {

  }, []);

  useEffect(() => {
    setShowThemeToggle(!location.pathname.includes("profile"));
  }, [location]);

  const logoSrc = isDarkTheme() ? darkLogo : lightLogo;

  return (
    // id is here for messages page to hide the footer then show it agian
    <div className="site-footer" id={siteFooterId}>
      <div className="laptop-screen-width-padding">
        <div className="footer-grid-container">
          <div className="theme-toggle-button" style={{display: showThemeToggle ? SHOW : HIDE}}>
            <ThemeToggleButton/>
          </div>
          <div className="footer-grid">
            <div className="footer-flex-col">
              <img alt={""} className="footer-logo" src={logoSrc}/>
              <UlLink link={twitterLink}
                      text={<div className={"social-container"}><TwitterIcon htmlColor={"hsl(0 0% var(--base-lightness))"} style={{fontSize: "24.4px"}}/> Twitter</div>}
                      newTab={true}/>
              <UlLink link={instaramLink}
                      text={<div className={"social-container"}><InstagramIcon htmlColor={"hsl(0 0% var(--base-lightness))"} style={{fontSize: "24.4px"}}/> Instagram</div>}
                      newTab={true}/>
              <UlLink link={discordLink}
                      text={<div className={"social-container"}><img className="footer-svg" src={DiscordIcon} alt="Discord Logo"/> Discord</div>}
                      newTab={true}/>
            </div>

            <div className="footer-top-padding">
              <h6 className="bold footer-title">Company</h6>
              <div className="footer-flex-col">
              </div>
            </div>

            <div className="footer-top-padding">
              <h6 className="bold footer-title">Resources</h6>
              <div className="footer-flex-col resources-container">
                <div className="footer-none-a-link" onClick={openCookieModal}>Cookie Settings</div>
              </div>
            </div>

            <div className="footer-top-padding">
              <h6 className="bold footer-title">Legal</h6>
              <div className="footer-flex-col">
              </div>
            </div>
          </div>
        </div>
        <Body2 className="copyright-text padding-block-self">© 2023 Artifyc Inc. All Rights Reserved.</Body2>
      </div>
    </div>
  )
}

export default Footer;
