import React, {CSSProperties, FunctionComponent, useEffect} from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./LoadingIcon.scss";

export const loadingIconId: string = "prompt-saving";

type LoadingIconsProps = {
  loadVisible?: boolean;
  size?: number,
  style?: CSSProperties,
}

const OurLoadingIcon: FunctionComponent<LoadingIconsProps> = (loadingIconProps) => {
  const {
    loadVisible,
    size,
    style
  } = loadingIconProps;

  useEffect(() => {

  }, []);

  return (
    <CircularProgress className={"loading-icon"}
                      id={loadingIconId}
                      size={size}
                      style={{...style, display: loadVisible ? "block" : "none"}}/>
  )
}

export default OurLoadingIcon;