import React, {createContext, FunctionComponent, useEffect, useState} from "react";
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import {Snackbar} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {createStyles, makeStyles, withStyles} from "@material-ui/core/styles";
import {useThemePreference} from "../ThemePreferenceContext/ThemePreferenceProvider";
import {clrBlack, clrBlackSecondary, clrIceBlue, clrWhite} from "../../style/MaterialUIStyling";
import Body1 from "../../components/Typography/Body1";
import {useCookies} from "react-cookie";
import {PWAInstallTracker} from "./PWAInstallTracker";
import {useLocation} from "react-router-dom";
import {useOnBoardingContext} from "../SignupOnboardingContext/OnboardingProvider";
import {isFullyOnboarded} from "../../utils/onBoardingUtils";
import OurToastContainer from "../../components/OurToastContainer/OurToastContainer";
import {toast} from "react-toastify";

const PWAInstallContext = createContext({});

const StyledSnackBar = withStyles((theme) => ({
  root: {
    "& .MuiSnackbarContent-root": {
      flexDirection: "column",
      alignItems: "center",
    },
    "& .MuiSnackbarContent-action": {
      marginLeft: "unset",
      paddingLeft: "unset",
    }
  }
}))(Snackbar)

const useSnackStyles = makeStyles(theme => createStyles({
  lightSnackBar: {
    backgroundColor: clrWhite,
    color: clrBlack,
  },
  darkSnackBar: {
    backgroundColor: clrBlackSecondary,
    color: clrIceBlue,
  }
}))

const showInstallPromptCookieName = "ShowInstallPrompt";
const PWAToastContainer = "PWAToastContainer"
const PWAToastSuccessId = "PWAToastSuccessId"

const PWAInstallProvider: FunctionComponent<{}> = ({children}) => {
  const [cookies, setCookies] = useCookies<string>([showInstallPromptCookieName]);
  const [installEvent, setInstallEvent] = useState<any | undefined>(undefined);
  const [openInstallPrompt, setOpenInstallPrompt] = useState<boolean>(false);
  const snackClasses = useSnackStyles();
  const {isDarkTheme} = useThemePreference();
  const {pathname} = useLocation();
  const onboardingStatus = useOnBoardingContext();
  const notifySuccess = (message) => toast(message, {type: "success", pauseOnHover: true, toastId: PWAToastSuccessId, containerId: PWAToastContainer});

  useEffect(() => {
    if (cookies[showInstallPromptCookieName] === undefined) {
      setCookies(showInstallPromptCookieName, "true", {path: "/"})
    }
  }, [])

  window.addEventListener("beforeinstallprompt", (event: any) => {
    event.preventDefault();
    setInstallEvent(event);
  });

  window.addEventListener("appinstalled", () => {
    setOpenInstallPrompt(false);
    setInstallEvent(undefined);
    notifySuccess("Thanks for installing Artistree!")

    if (document.visibilityState === "visible") {
      PWAInstallTracker.appInstalled();
    }

  });

  useEffect(() => {
    if (installEvent && cookies[showInstallPromptCookieName] !== "false" && pathname.includes("/profile") && isFullyOnboarded(onboardingStatus)) {
      PWAInstallTracker.promptShown();
      setOpenInstallPrompt(true);
    }
  }, [installEvent, pathname])

  return <PWAInstallContext.Provider value={{}}>
    <StyledSnackBar open={openInstallPrompt}
                    ContentProps={{
                      className: isDarkTheme() ? snackClasses.darkSnackBar : snackClasses.lightSnackBar,
                    }}
                    message={<div className="center-text" style={{maxWidth: "35ch"}}>
                      <GetAppRoundedIcon color="primary"/>
                      <Body1 className="semi-bold">
                        Access Artistree easily by installing our app!
                      </Body1>
                    </div>}
                    action={<div className="flex-row-gap-wrap auto-inline-self">
                      <Button size="small" onClick={() => {
                        setOpenInstallPrompt(false);
                        PWAInstallTracker.ourPromptDismissed();
                        setCookies(showInstallPromptCookieName, "false", {path: "/", maxAge: 60 * 60 * 24 * 90})
                      }}>Dismiss</Button>
                      <Button color="primary" size="small" onClick={() => {
                        setOpenInstallPrompt(false);

                        PWAInstallTracker.ourPromptAccepted();

                        installEvent.prompt();
                        installEvent.userChoice.then((choiceResult: any) => {
                          if (choiceResult.outcome === "accepted") {
                            PWAInstallTracker.browserPromptAccepted();
                          } else {
                            PWAInstallTracker.browserPromptDismissed();
                            setCookies(showInstallPromptCookieName, "false", {path: "/", maxAge: 60 * 60 * 24 * 90})
                          }
                        }).then(() => setInstallEvent(undefined))
                      }}>Install</Button>
                    </div>}/>
    {children}
    <OurToastContainer containerId={PWAToastContainer}/>
  </PWAInstallContext.Provider>
}

export {PWAInstallProvider, PWAInstallContext};