import React, {FunctionComponent, useEffect, useRef, useState} from "react";
import {CircularProgressbar} from "react-circular-progressbar";
import uniqueId from "lodash/uniqueId";
import {DisplayOptions, HIDE, SHOW} from "../../utils/displayUtils";
import 'react-circular-progressbar/dist/styles.css';
import Body2 from "../Typography/Body2";


export type OurCircularProgressBarProps = {
  currentCount: number,
  displayed: boolean,
  bottomText?: JSX.Element,
  topText?: JSX.Element,
  totalCount: number,
  percentage?: boolean,
  overrideColor?: string,
};

const OurCircularProgressBar: FunctionComponent<OurCircularProgressBarProps> = (props) => {
  const {
    currentCount,
    displayed,
    bottomText,
    topText,
    totalCount,
    percentage,
    overrideColor,
  } = props;
  const ourCircularProgressBarId = useRef<string>(uniqueId("our-circular-progress-bar-id-"));
  const [circularProgressBarDisplay, setCircularProgressBarDisplay] = useState<DisplayOptions>(HIDE);

  useEffect(() => {

  }, []);

  useEffect(() => {

  }, [currentCount]);

  useEffect(() => {
    if (displayed) {
      setCircularProgressBarDisplay(SHOW);
    } else {
      setCircularProgressBarDisplay(HIDE);
    }
  }, [displayed]);


  return (<div className="flex-col-gap"
               style={{display: circularProgressBarDisplay}}
  >
    {topText ?
      <div className="padding-block-self">
        {topText}
      </div> : undefined
    }
    <div style={{
      height: "70px",
      marginLeft: "auto",
      marginRight: "auto",
      width: "70px",
    }}>
      <CircularProgressbar minValue={0}
                           maxValue={totalCount}
                           text={percentage ? (`${currentCount}` + "%") : (`${currentCount}` + "/" + totalCount)}
                           styles={{
                             path: {
                               stroke: overrideColor || "#39B54A",
                             },
                             text: {
                               fill: overrideColor || "#8AC440",
                             }
                           }}
                           value={currentCount}
      />
    </div>
    {bottomText ?
      <Body2 className="padding-block-self">
        {bottomText}
      </Body2>
      : undefined
    }
  </div>);
}

export default OurCircularProgressBar;