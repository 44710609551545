import React, {useEffect} from "react"
import "../UlLink/UtilsStyles.css"

export default function HelpEmail() {
  useEffect(() => {

  }, []);

  const helpEmail = "support@artistree.io"
  return(<a className="UlLinks" href={"mailto:" + helpEmail}>{helpEmail}</a>)
}