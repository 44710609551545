import React, {FunctionComponent, useEffect, useState} from 'react';
import '@asseinfo/react-kanban/dist/styles.css'
import './MarketplaceTool.scss'
import '../../../App.scss'
import {toast} from "react-toastify";
import OurToastContainer from "../../../components/OurToastContainer/OurToastContainer";
import MessageBox from "../../../components/MessageBox/MessageBox";
import {Pagination} from "@material-ui/lab";
import usePagination from "../../../components/Pagination/Pagination";
import {Auth} from "aws-amplify";
import {fetchPostBody} from "../../../utils/fetchUtils";
import LoadingGif from "../../../components/LoadingGif/LoadingGif";
import ImgWithLoading from "../../../components/ImgWithLoading/ImgWithLoading";
import OurDivider from '../../../components/OurDividers/OurDivider';
import Body1 from '../../../components/Typography/Body1';
import {useSearchParams} from 'react-router-dom';
import {Button} from '@material-ui/core';
import OurPaperModal from "../../../components/OurPaperModal/OurPaperModal";
import TextField from "@material-ui/core/TextField";
import AsyncActionButton from "../../../components/AsyncActionButton/AsyncActionButton";
import CopyText from "../../../components/CopyText/CopyText";
import UlLink from "../../../components/UlLink/UlLink";
import IconButton from "@material-ui/core/IconButton";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import OpenInNewOutlinedIcon from '@material-ui/icons/OpenInNewOutlined';
import Caption from "../../../components/Typography/Caption";


const MarketplaceToolToastContainerId = "MarketplaceToolToastContainerId";
const MarketplaceToolToastErrorId = "MarketplaceToolToastErrorId";
const MarketplaceToolToastSuccessId = "MarketplaceToolToastSuccessId";


const usersPerPage = 10;

export type ApplyingUser = {
  username: string,
  profilePic: string,
  socialMediaLink: string,
  numPaidCommissions: number,
  types: {
    type: string,
    price: number,
    typeImages: string[],
  }[]
}

type MarketplaceToolProps = {
  showIgnoredUsersOnly?: boolean,
}

const MarketplaceTool: FunctionComponent<MarketplaceToolProps> = (props) => {

  const {
    showIgnoredUsersOnly,
  } = props;

  const [searchParams, setSearchParam] = useSearchParams();

  const [currPageNumber, setCurrPageNumber] = useState<number>((parseInt(searchParams.get("page") || "1")));
  const [authIds, setAuthIds] = useState<string[] | undefined>(undefined);
  const [hydratedPageData, setHydratedPageData] = useState<Array<ApplyingUser>>([]);

  const [isAddModalOpen, setIsAddOpen] = useState<boolean>(false);
  const [toBeAddedUser, setToBeAddedUser] = useState<string | undefined>(undefined);
  const [toBeAddedRank, setToBeAddedRank] = useState<number | undefined>(undefined);

  const [isIgnoreModalOpen, setIsIgnoreModalOpen] = useState<boolean>(false);
  const [toBeIgnoredUser, setToBeIgnoredUser] = useState<string | undefined>(undefined);

  const pageData = usePagination(authIds, usersPerPage, currPageNumber, setCurrPageNumber);

  const notifyError = (message) => toast(message, {type: "error", pauseOnHover: true, toastId: MarketplaceToolToastErrorId, containerId: MarketplaceToolToastContainerId});
  const notifySuccess = (message) => toast(message, {type: "success", pauseOnHover: true, toastId: MarketplaceToolToastSuccessId, containerId: MarketplaceToolToastContainerId});

  useEffect(() => {
    updateUsers();
  }, [])

  useEffect(() => {
    if (pageData.currentData.length > 0) {
      Auth.currentAuthenticatedUser({bypassCache: false})
        .then(currUser => {
          return fetchPostBody<Array<ApplyingUser>>("admin/action", {
            "action": "getUsersInfo",
            "authIds": pageData.currentData
          }, currUser.getSignInUserSession()?.getIdToken().getJwtToken())
            .then((res) => {
              setHydratedPageData(res);
            })
            .then(closeAddModal)
            .then(closeIgnoreModal)
        })
    } else {
      closeAddModal()
      closeIgnoreModal()
    }
  }, [pageData.currentData])

  useEffect(() => {
    if (currPageNumber === 1) {
      searchParams.set("page", "1");
      setSearchParam(searchParams);
    }
  }, [currPageNumber])

  function updateUsers() {
    // setUserNames(undefined)
    // setHydratedPageData([])

    Auth.currentAuthenticatedUser({bypassCache: false})
      .then(currUser => {
        return fetchPostBody("admin/action", {"action": showIgnoredUsersOnly ? "getIgnoredUsers" : "getAppliedUsers"}, currUser.getSignInUserSession()?.getIdToken().getJwtToken())
          .then((res) => {
            const authIds = [];
            // @ts-ignore
            Object.entries(res).forEach(([key, value]) => {
              // @ts-ignore
              authIds.push(key);
            })
            setHydratedPageData([]);
            setAuthIds(authIds);
          })
      })
  }


  function handleUserAdd() {
    return Auth.currentAuthenticatedUser({bypassCache: false})
      .then(currUser => {
        return fetchPostBody("admin/action", {
          "action": "AddUser",
          "username": toBeAddedUser,
          "rank": toBeAddedRank,
        }, currUser.getSignInUserSession()?.getIdToken().getJwtToken())
          .then(updateUsers)
          .then(closeAddModal)
          .then(() => notifySuccess("User added successfully!"))
          .catch((err) => notifyError("Something went wrong, talk to Yazeed"))
      })
  }

  function handleUserIgnore() {
    return Auth.currentAuthenticatedUser({bypassCache: false})
      .then(currUser => {
        return fetchPostBody("admin/action", {
          "action": "ignoreUser",
          "username": toBeIgnoredUser,
        }, currUser.getSignInUserSession()?.getIdToken().getJwtToken())
          .then(updateUsers)
          .then(closeIgnoreModal)
          .then(() => notifySuccess("User ignored successfully!"))
          .catch((err) => notifyError("Something went wrong, talk to Yazeed"))
      })
  }

  function closeAddModal() {
    setIsAddOpen(false)
    setToBeAddedUser(undefined)
    setToBeAddedRank(undefined)
  }

  function closeIgnoreModal() {
    setIsIgnoreModalOpen(false)
    setToBeIgnoredUser(undefined)
  }

  function copyText(text) {
    navigator.clipboard.writeText(text)
      .then(() => {
        notifySuccess("Copied to clipboard!")
      })
      .catch(() => {
        notifyError("Something went wrong")
      })
  }

  function userAndRankPick(pickedUser, rank: number) {
    setToBeAddedUser(pickedUser.username)
    setToBeAddedRank(rank)
    setIsAddOpen(true)
  }

  let isRankValid = toBeAddedRank !== undefined && !isNaN(toBeAddedRank) && toBeAddedRank >= 1 && toBeAddedRank <= 3;

  function handleEnterKeyPress(event) {
    if (event.keyCode === 13 && isRankValid) {
      handleUserAdd();
    }

  }

  return (
    <>
      <MessageBox text={showIgnoredUsersOnly ? "Ignored users" : "Marketplace tool"}/>
      <div className='laptop-l-screen-width-padding center-text padding-inline-screen'>
        {authIds === undefined && <LoadingGif/>}
        {hydratedPageData.length > 0 ? hydratedPageData.map((applyingUser) => <>
            <div className="flex-col-no-align-gap left-text">
              <div className="flex-row">
                <div>
                  <div className="flex-row">
                    <h4 className="semi-bold">{applyingUser.username}</h4>
                    <IconButton onClick={() => copyText(applyingUser.username)}
                                size="medium">
                      <FileCopyOutlinedIcon className={"copy-icon pointer clr-grey"}/>
                    </IconButton>
                    <IconButton onClick={() => window.open(`https://artistree.io/${applyingUser.username}`, "_blank")}
                                size="medium">
                      <OpenInNewOutlinedIcon className={"copy-icon pointer clr-grey"}/>
                    </IconButton>
                  </div>
                  <div className="flex-col-no-align-gap">
                    {applyingUser.profilePic && <CopyText copyText={applyingUser.profilePic}>
                      <Body1 className="padding-block-self">
                        <UlLink link={applyingUser.profilePic} text={"Profile pic"} newTab={true}/>
                      </Body1>
                    </CopyText>}
                    {applyingUser.socialMediaLink && <CopyText copyText={applyingUser.socialMediaLink}>
                      <Caption className="padding-block-self">
                        <UlLink link={applyingUser.socialMediaLink} newTab={true}/>
                      </Caption>
                    </CopyText>}
                    {applyingUser.numPaidCommissions > 0 && <div className="flex-row-gap padding-block-self">
                      <h6 className="semi-bold body1Point5">Paid commissions:</h6>
                      <Body1>{applyingUser.numPaidCommissions}</Body1>
                    </div>}
                  </div>
                </div>
                <div className="flex-row-half-gap-wrap-no-grow"
                     style={{marginLeft: "auto"}}>
                  <IconButton color="default"
                              onClick={() => userAndRankPick(applyingUser, 1)}
                  >
                    <h5 className="padding-inline-self">1</h5>
                  </IconButton>
                  <IconButton color="default"
                              onClick={() => userAndRankPick(applyingUser, 2)}
                  >
                    <h5 className="padding-inline-self">2</h5>
                  </IconButton>
                  <IconButton color="default"
                              onClick={() => userAndRankPick(applyingUser, 3)}
                  >
                    <h5 className="padding-inline-self">3</h5>
                  </IconButton>
                  {(showIgnoredUsersOnly === undefined || !showIgnoredUsersOnly) &&
                    <Button variant="contained"
                            onClick={() => {
                              setToBeIgnoredUser(applyingUser.username)
                              setIsIgnoreModalOpen(true)
                            }}>
                      Ignore
                    </Button>}
                </div>
              </div>
              <div>
                {applyingUser.types.map((type, index) => <div className="padding-block-self" key={index}>
                    <h6 className="semi-bold">{type.type}</h6>
                    <div className="flex-row-gap padding-bottom">
                      <Body1 className="semi-bold">Price:</Body1>
                      <Body1>${type.price}</Body1>
                    </div>
                    <div className="flex-row-gap">
                      {type.typeImages.map((image, index) => <ImgWithLoading currIndex={index}
                                                                             dimension={"250px"}
                                                                             img={image}
                                                                             otherImages={type.typeImages}/>)}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <OurDivider/>
          </>
        ) : authIds?.length !== 0 ? <LoadingGif/> : <MessageBox text={"No users to add, that's all folks!"}/>}

        <OurPaperModal parentToggle={isAddModalOpen}
                       setParentClose={closeAddModal}
                       leftSideElement={<h6 className="semi-bold">Add {toBeAddedUser} to marketplace?</h6>}>
          <TextField label="Rank"
                     margin={"dense"}
                     variant={"outlined"}
                     type={"number"}
                     onChange={(event) => setToBeAddedRank(parseInt(event.target.value))}
                     onKeyDown={handleEnterKeyPress}
                     value={toBeAddedRank}
                     fullWidth={true}/>

          <div className="flex-row-gap margin-auto-self padding-top">
            <AsyncActionButton color="primary"
                               disabled={isRankValid}
                               action={handleUserAdd}
                               autoFocus={true}
                               text={"Yes"}
                               variant="contained"/>
            <Button variant="contained"
                    onClick={closeAddModal}>
              no
            </Button>
          </div>

        </OurPaperModal>

        <OurPaperModal parentToggle={isIgnoreModalOpen}
                       setParentClose={closeIgnoreModal}
                       leftSideElement={<h6 className="semi-bold">Ignore {toBeIgnoredUser} request?</h6>}>
          <div className="flex-row-gap margin-auto-self padding-top">
            <AsyncActionButton color="primary"
                               action={handleUserIgnore}
                               text={"Yes"}
                               autoFocus={true}
                               variant="contained"/>
            <Button variant="contained"
                    onClick={closeAddModal}>
              no
            </Button>
          </div>

        </OurPaperModal>
        <div className="padding-self"/>
        <div className="flex-row-gap">
          {authIds !== undefined && <Pagination count={Math.ceil(authIds.length / usersPerPage)}
                                                size="large"
                                                page={currPageNumber}
                                                variant="outlined"
                                                shape="rounded"
                                                onChange={(event, page) => {
                                                  setHydratedPageData([])
                                                  setCurrPageNumber(page);
                                                  searchParams.set("page", page.toString());
                                                  setSearchParam(searchParams);
                                                  pageData.jumpToPage(page);

                                                }}
                                                style={{marginInline: "auto"}}
          />}
        </div>
      </div>
      <OurToastContainer containerId={MarketplaceToolToastContainerId}/>
    </>
  );

}

export default MarketplaceTool;
