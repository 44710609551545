import React, {FunctionComponent, useEffect} from "react";
import "./WelcomeAdmin.scss";
import "../../../style/Layout.scss";
import "../../../style/Styling.scss";
import "../homePage.scss";
import {useThemePreference} from "../../../contexts/ThemePreferenceContext/ThemePreferenceProvider";
import {setPageTitle} from "../../../utils/titleUtils";

const WelcomeAdmin: FunctionComponent<{}> = () => {
  const {isDarkTheme} = useThemePreference();

  useEffect(() => {
    setPageTitle("Welcome, Admins");
  }, []);

  return (<div className="laptop-l-screen-width no-fees-container">
    <div className="flex-row-gap auto-in-between padding-screen">
      <div className="small-text-width no-fees-text">
        <h3 className="bold">Welcome, <span className="clr-red">Admins!</span></h3>
      </div>
    </div>
  </div>);
}


export default WelcomeAdmin;