import React, {FunctionComponent, useEffect, useState} from "react"
import "./UtilsStyles.css"
import {Link as RouterLink} from "react-router-dom";

export type UlLinkProps = {
  link: string,
  newTab?: boolean
  text?: string | any,
  reloadDocument?: boolean,
}

const UlLink: FunctionComponent<UlLinkProps> = (ulLinkProps) => {
  const {link, newTab, text, reloadDocument} = ulLinkProps;
  const displayText = text === undefined ? link : text

  const [fullUrl, setFullUrl] = useState<boolean>(false);

  useEffect(() => {

  }, []);

  useEffect(() => {
    setFullUrl(link.startsWith("http"))
  }, [link]);

  return (<>
    {fullUrl || newTab ? <a className="UlLinks" rel={newTab ? "external nofollow noopener" : ""} target={newTab ? "_blank" : ""} href={link}>{displayText}</a> :
      <RouterLink reloadDocument={reloadDocument !== undefined ? reloadDocument : true} className="UlLinks" to={link} replace={false}>{displayText}</RouterLink>
    }
  </>)

}

export default UlLink;