import {createContext, FunctionComponent, useContext, useEffect, useState} from "react";
import {Auth} from "aws-amplify";

type GlobalUserInfoType = {
  username: string,
  profilePictureUrl: string | undefined,
}

type UserContextType = {
  globalUserInfo: GlobalUserInfoType | undefined,
  setGlobalUserInfo: (userInfo: GlobalUserInfoType) => void,
}

const UserContext = createContext<UserContextType>({
  globalUserInfo: undefined,
  setGlobalUserInfo: () => {
  },
})

const useUserContext = () => useContext(UserContext);

const USER_INFO_INIT_KEY = "user-info-";

const UserContextProvider: FunctionComponent<{}> = ({children}) => {
  const [userInfoKey, setUserInfoKey] = useState<string | undefined>(undefined);
  const [globalUserInfo, setGlobalUserInfo] = useState<GlobalUserInfoType>();

  useEffect(() => {
    Auth.currentAuthenticatedUser({bypassCache: false})
      .then(currUser => {
        const userInfoNewKey = USER_INFO_INIT_KEY + currUser.username.substring(0, 12);
        setUserInfoKey(userInfoNewKey);
      }).catch(() => {
    });
  }, []);

  useEffect(() => {
    if (userInfoKey) {
      setGlobalUserInfo(JSON.parse(window.localStorage.getItem(userInfoKey) || "{}"));
    }
  }, [userInfoKey]);

  return (<UserContext.Provider value={{
      globalUserInfo: globalUserInfo,
      setGlobalUserInfo: (globalUserInfo) => {
        if (!userInfoKey) { // this shouldn't be possible given how the context loads but just in case
          return;
        }
        setGlobalUserInfo(globalUserInfo);
        window.localStorage.setItem(userInfoKey, JSON.stringify(globalUserInfo));
      }
    }}>
      {children}
    </UserContext.Provider>
  )
}

export {useUserContext, UserContextProvider}