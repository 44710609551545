import React, {FunctionComponent, useEffect} from 'react';
import WelcomeAdmin from './WelcomeAdmin/WelcomeAdmin';

const HomePage: FunctionComponent<{}> = () => {

  useEffect(() => {
    document.title = "Artistree";
  }, [])

  return (<>
    <WelcomeAdmin/>
  </>);
}

export default HomePage;