import React, {useEffect, useState} from "react";

function usePagination(data, itemsPerPage, currentPage, setCurrentPage) {
  const [safeData, setSafeData] = useState(data || [])
  // const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState([]);
  const maxPage = Math.ceil(safeData.length / itemsPerPage);

  useEffect(() => {

  }, [])

  useEffect(() => {
    setSafeData(data || []);
  }, [data])

  useEffect(() => {
    const begin = (currentPage - 1) * itemsPerPage;
    const end = begin + itemsPerPage;
    setCurrentData(safeData.slice(begin, end));

  }, [safeData, itemsPerPage, currentPage])

  function next() {
    setCurrentPage(currentPage => Math.min(currentPage + 1, maxPage));
  }

  function prev() {
    setCurrentPage(currentPage => Math.max(currentPage - 1, 1));
  }

  function jumpToPage(page) {
    const pageNumber = Math.max(1, page);
    setCurrentPage(currentPage => Math.min(pageNumber, maxPage));
  }

  return {next, prev, jumpToPage, currentData, currentPage, maxPage};
}

export default usePagination;