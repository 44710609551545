import React, {FunctionComponent, Suspense, useCallback, useEffect, useState} from 'react';
import {Auth} from "aws-amplify";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import HomePage from '../HomePage/homePage';
import NavBar from './NavBar';
import FooterBar from '../Footer/footer'
import LoadingGif from "../LoadingGif/LoadingGif";
import {ErrorBoundary} from "react-error-boundary";
import ErrorMessage from "../ErrorBoundary/ErrorMessage";
import {logErrorToBackend} from "../../utils/erroryLoggingUtils";
import {CognitoUser} from "amazon-cognito-identity-js";
import {lazyWithRetry} from "../../utils/LazyLoadingUtils";
import {useThemePreference} from "../../contexts/ThemePreferenceContext/ThemePreferenceProvider";
import {getMaterialUIStyling} from "../../style/MaterialUIStyling";
import {ThemeProvider} from "@material-ui/core/styles";
import {ConsentCookiesProvider} from "../../contexts/CookieSettingsContext/CookieSettingsContext";
import "../../style/Styling.scss";
import "../../style/Layout.scss";
import UserLookupTool from "../../routes/adminTools/UserLookupTool/UserLookupTool"
import OrderLookupTool from "../../routes/adminTools/OrderLookupTool/OrderLookupTool"
import StripeResetTool from "../../routes/adminTools/StripeResetTool/StripeResetTool"
import ConfirmEmail from "../../routes/adminTools/ConfirmTool/ConfirmEmail"
import MarketplaceTool from "../../routes/adminTools/MarketplaceTool/MarketplaceTool"
import {PWAInstallProvider} from "../../contexts/PWAInstallContext/PWAInstallContext";
import PayPalResetTool from "../../routes/adminTools/PayPalResetTool/PayPalResetTool";
import MarketplaceAccept from "../../routes/adminTools/MarketplaceAccept/MarketplaceAccept";
import DeleteCommission from "../../routes/adminTools/DeleteCommission/DeleteCommission";
import ResetMFA from "../../routes/adminTools/resetMFA/ResetMFA";

const Dashboard = lazyWithRetry(() => import('../../routes/dashboard/Dashboard'));

const SignIn = lazyWithRetry(() => import("../../routes/signIn/SignIn"));

const HeaderBar: FunctionComponent<{}> = () => {
  const [currUser, setCurrUser] = useState<CognitoUser>();
  const [loggedInChecked, setLoggedInChecked] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();

  const {themePreference, isDarkTheme} = useThemePreference()

  const checkIfUserLoggedIn = useCallback(() => {
    Auth.currentAuthenticatedUser({bypassCache: false})
      .then(user => {
        const groups = user.signInUserSession.accessToken.payload["cognito:groups"];
        //only log them in successfully if their account has "admin" tied to it
        if (groups.includes('admin')) {
          setCurrUser(user)
          setLoggedInChecked(true)
        }
      })
      .catch(() => {
      })
  }, [location]);

  const getSharedRoutes = useCallback(() => {
    return [
      {route: "/", element: <HomePage/>},
      {route: "/sign-in", element: <SignIn/>},
    ]
  }, []);

  const getLoggedInRoutes = useCallback(() => {
    return [
      {route: "/dashboard", element: <Dashboard/>},
      {route: "/email-confirm", element: <ConfirmEmail/>},
      {route: "/user-lookup", element: <UserLookupTool/>},
      {route: "/order-lookup", element: <OrderLookupTool/>},
      {route: "/stripe-reset", element: <StripeResetTool/>},
      {route: "/paypal-reset", element: <PayPalResetTool/>},
      {route: "/marketplace", element: <MarketplaceTool/>},
      {route: "/marketplace-accept", element: <MarketplaceAccept/>},
      {route: "/marketplace-ignore", element: <MarketplaceTool showIgnoredUsersOnly={true}/>},
      {route: "/delete-commission", element: <DeleteCommission/>},
      {route: "/mfa-reset", element: <ResetMFA/>}
    ];
  }, []);

  const getNotLoggedInRoutes = useCallback(() => {
    return [
      {route: "/no-admin", element: <Dashboard/>}
    ]
  }, []);

  useEffect(() => {
    checkIfUserLoggedIn();
    // TODO: I think it is safe to remove this, but who is it hurting but the cpu, my pride healed after finding another solution
    const path = (/#!(\/.*)$/.exec(location.hash) || [])[1];
    if (path) {
      navigate(path, {replace: true});
    }
  }, [])

  useEffect(() => {
    checkIfUserLoggedIn();
    setIsError(false);
  }, [location]);

  function onError(error: Error, info: { componentStack: string }) {
    setIsError(true);
    logErrorToBackend(error, info, window.location.origin + location.pathname);
  }

  return (
    // These providers are here and not in App.tsx because they rely on the theme provider. They also need to change based on the theme preference change
    <ThemeProvider theme={getMaterialUIStyling(themePreference, isDarkTheme())}>
      <ConsentCookiesProvider>
        <PWAInstallProvider>
          <div>
            <div className="flex-col-no-align app-content-container">
              <NavBar currUser={currUser}
                      isError={isError}/>
              <ErrorBoundary FallbackComponent={ErrorMessage}
                             onError={onError}
                // reset the internal state of the error boundary when the user navigates away
                             resetKeys={[location]}>
                <div style={{minHeight: "500px"}}>
                  {/* Slight improvement to LCP */}
                  {location.pathname === "/"}
                  {loggedInChecked === undefined && location.pathname !== "/" ? <LoadingGif/> :
                    <Suspense fallback={<LoadingGif/>}>
                      <Routes>
                        {currUser ? getLoggedInRoutes().map(toRoute) : getNotLoggedInRoutes().map(toRoute)}
                        {getSharedRoutes().map(toRoute)}
                      </Routes>
                    </Suspense>}
                </div>
              </ErrorBoundary>
              <footer style={{marginTop: "auto"}}><FooterBar/></footer>
            </div>
          </div>
        </PWAInstallProvider>
      </ConsentCookiesProvider>
    </ThemeProvider>
  )
}

function toRoute(routeElement, index): JSX.Element {
  if (routeElement.nestedRoutes) {
    return <Route path={routeElement.route} element={routeElement.element} key={index}>
      {routeElement.nestedRoutes.map((nestedRoute, idx) => (
        <Route path={nestedRoute.route} element={nestedRoute.element} key={idx}/>
      ))}
    </Route>
  }

  return <Route path={routeElement.route} element={routeElement.element} key={index}/>
}

export default HeaderBar;