import React, {CSSProperties, FunctionComponent, useEffect} from "react";
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import "./CopyText.scss";
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Body2 from "../Typography/Body2";
import OurToastContainer from "../OurToastContainer/OurToastContainer";

type BorderedCopyTextProps = {
  copyText: string,
  dontUseToastComp?: boolean
  className?: string,
  style?: CSSProperties,
}

const toastId = "copied-text-id"
const copyToastContainerId = "copy-toast-container-id"

const CopyText: FunctionComponent<BorderedCopyTextProps> = (borderedCopyTextProps) => {
  const {
    children,
    className,
    copyText,
    dontUseToastComp,
    style,
  } = borderedCopyTextProps;
  const notifySuccess = (message) => toast(message, {type: "success", pauseOnHover: true, toastId: toastId, containerId: copyToastContainerId});
  const notifyError = (message) => toast(message, {type: "error", pauseOnHover: true, toastId: toastId, containerId: copyToastContainerId});

  useEffect(() => {

  }, []);

  function copyToClipBoard() {
    navigator.clipboard.writeText(copyText)
      .then(() => {
        notifySuccess("Copied to clipboard!")
      })
      .catch(() => {
        notifyError("Something went wrong")
      })
  }

  return (
    <>
      <div className={"bordered-default-text flex-inline-half-gap " + className} style={{...style, width: "fit-content"}}>
        <Body2 className="copy-text-body">
          {children}
        </Body2>
        <div className="copy-icon-container">
          <FileCopyOutlinedIcon className={"copy-icon pointer clr-grey"} onClick={copyToClipBoard}/>
        </div>
      </div>

      {dontUseToastComp ? undefined : <OurToastContainer containerId={copyToastContainerId}/>}
    </>
  )
}

export default CopyText;