import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {createTheme, makeStyles, styled, withStyles} from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import IconButton from "@material-ui/core/IconButton";
import {DARK_THEME, ThemePreferences, useThemePreference} from "../contexts/ThemePreferenceContext/ThemePreferenceProvider";
import Tooltip from "@material-ui/core/Tooltip";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";

export const clrPrimaryGreen = "hsl(128, 52%, 47%)";
export const clrRed = "hsl(335, 82%, 54%)";
const clrPurple = "hsl(279, 87%, 69%)"
const clrYellow = 'hsl(45, 100%, 60%)';
export const clrBlack = "hsl(345, 6%, 13%)";
export const clrPrimaryBlue = "hsl(198, 74%, 51%)"
export const clrSecondaryBlue = "hsl(220, 83%, 50%)"
export const clrPrimaryBlueLight = "hsl(179, 98%, 35%)"
export const clrSecondaryBlueLight = "hsl(185, 90%, 62%)";
export const clrGrey = "hsl(0, 0%, 42%)";
export const clrWhite = "#fff";

// Dark theme colors
export const clrBlackSecondary = "#0d1117";
export const clrBlueGrey = "#161b22";
export const clrIceBlue = "#c9d1d9";
export const clrGreyBorder = "#2a2e33";

const body1 = 15.4;
const horizontalPaddingConstant = "0.5rem";

const hideNumberArrows = {
  '& input[type=number]': {
    '-moz-appearance': 'textfield'
  },
  '& input[type=number]::-webkit-outer-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0
  },
  '& input[type=number]::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0
  }
}

const outlinedButtonBorder = "2.5px solid";

export const getMaterialUIStyling = (themePreference: ThemePreferences, isDarkTheme: boolean) => {

  return createTheme({
    palette: {
      type: themePreference,
      background: {
        default: isDarkTheme ? clrBlackSecondary : "#fafafa",
        paper: isDarkTheme ? clrBlackSecondary : "#fff"
      },
      common: {
        black: clrBlack
      },
      primary: {
        main: clrPrimaryGreen,
        contrastText: clrWhite,
        dark: "hsl(128, 52%, 44%)",
        light: "hsl(128, 52%, 80%)"
      },
      secondary: {
        main: "hsl(86, 53%, 51%)",
        contrastText: clrWhite,
        dark: "hsl(86, 53%, 48%)"
      },
      error: {
        main: clrRed
      },
      warning: {
        main: clrYellow,
        contrastText: clrBlack,
        dark: "hsl(45, 100%, 57%)"
      },
      info: {
        main: clrPrimaryBlue,
        contrastText: clrWhite,
        dark: "hsl(198, 74%, 48%)"
      },
      success: {
        main: clrPrimaryGreen,
        contrastText: clrWhite,
        dark: "hsl(128, 52%, 44%)"
      },
      text: {
        primary: isDarkTheme ? clrIceBlue : clrBlack,
        secondary: isDarkTheme ? clrIceBlue : clrGrey
      },
    },
    shape: {
      borderRadius: 10
    },
    overrides: {
      MuiAppBar: {
        colorPrimary: {
          backgroundColor: isDarkTheme ? clrBlueGrey : clrWhite
        }
      },
      MuiPaper: {
        rounded: {
          border: "1px solid " + (isDarkTheme ? clrGreyBorder : "lightgrey"),
          backgroundColor: isDarkTheme ? clrBlackSecondary : "#fff"
        }
      },
      MuiButton: {
        root: {
          fontSize: 13.4,
        },
        textSizeLarge: {
          fontSize: "13.4px",
          letterSpacing: "1.25px"
        },
        textSizeSmall: {
          fontSize: "13.4px",
          letterSpacing: "1.25px"
        },
        containedSizeSmall: {
          fontSize: 15
        },
        outlinedSecondary: {
          border: outlinedButtonBorder,
          '&:hover': {
            border: outlinedButtonBorder
          }
        },
        outlinedPrimary: {
          border: outlinedButtonBorder,
          '&:hover': {
            border: outlinedButtonBorder
          }
        },
        outlined: {
          border: outlinedButtonBorder,
          '&:hover': {
            border: outlinedButtonBorder
          },
          color: isDarkTheme ? clrIceBlue : clrGrey
        },
        text: {
          color: isDarkTheme ? clrIceBlue : clrGrey
        }
      },
      MuiButtonBase: {
        root: {
          fontSize: 13.4,
          letterSpacing: 0.5,
        }
      },
      MuiOutlinedInput: {
        root: {
          ...hideNumberArrows,
        },
        input: {
          paddingTop: 12,
          paddingBottom: 12,
          paddingLeft: 10,
          paddingRight: 10,
        },
        notchedOutline: {
          // I'm glad you're seeing this ✨magic number✨, so based on the comment linked below, material ui figures out the notched outline width based on the font size.
          // https://github.com/mui/material-ui/issues/16833#issuecomment-1136374597
          // The exact number was based on trial and error. Out of all the possible things I can be doing right now and all the possible timelines I can be on, I'm here...
          fontSize: 14.70,
        }
      },
      MuiInputBase: {
        root: {
          fontSize: 13.4
        }
      },
      MuiFormHelperText: {
        contained: {
          marginLeft: 7,
          marginRight: 7,
        }
      },
      MuiInput: {
        root: {
          ...hideNumberArrows,
        }
      },
      MuiInputLabel: {
        outlined: {
          background: isDarkTheme ? "inherit" : "white",
        }
      },
      MuiBottomNavigationAction: {
        label: {
          fontSize: 11,
          minHeight: 32,
          maxWidth: 80,
          "&$selected": {
            fontSize: 11.5,
          }
        }
      },
      MuiTable: {
        root: {
          border: "1px solid lightgrey",
          borderRadius: "10px",
          borderCollapse: "separate"
        }
      },
      MuiTooltip: {
        tooltip: {
          fontSize: 12,
          letterSpacing: 0.4,
          fontWeight: 400,
          color: isDarkTheme ? clrIceBlue : clrWhite,
          backgroundColor: isDarkTheme ? clrGreyBorder : "hsla(0, 0%, 38%, 0.9)",
        },
        arrow: {
          color: isDarkTheme ? clrGreyBorder : "hsla(0, 0%, 38%, 0.9)",
        }
      },
      MuiTableRow: {
        root: {
          "&:last-child > *": {
            borderBottom: "unset",
          }
        }
      },
      MuiTableCell: {
        root: {
          fontSize: body1,
          padding: horizontalPaddingConstant + " 0",
          borderRight: "1px solid lightgrey",
          paddingLeft: "0.5rem",
          "&:nth-child(1)": {
            fontWeight: 500,
            paddingRight: "0.25rem"
          },
          "&:last-child": {
            borderRight: "unset"
          }
        }
      },
      MuiChip: {
        outlined: {
          backgroundColor: isDarkTheme ? clrBlueGrey : "initial",
        },
        deleteIcon: {
          color: isDarkTheme ? "lightgray" : clrGrey
        }
      },
      MuiTab: {
        root: {
          marginLeft: "auto",
          marginRight: "auto",
        }
      },
      MuiSvgIcon: {
        colorAction: {
          color: isDarkTheme ? clrIceBlue : "grey",
        }
      },
    },
    typography: {
      htmlFontSize: body1,
      fontSize: body1,
      h1: {
        fontSize: 92.5,
        lineHeight: 1,
        letterSpacing: -1.5
      },
      h2: {
        fontSize: 57.8,
        lineHeight: 1.15,
        letterSpacing: -0.5
      },
      h3: {
        fontSize: 46.2,
        lineHeight: 1.25,
        letterSpacing: 0
      },
      h4: {
        fontSize: 32.7,
        lineHeight: 1.35,
        letterSpacing: 0.25
      },
      h5: {
        fontSize: 23.1,
        lineHeight: 1.45,
        letterSpacing: 0
      },
      h6: {
        fontSize: 19.2,
        lineHeight: 1.5,
        letterSpacing: 0.15
      },
      body1: {
        fontSize: body1,
        lineHeight: 1.5,
        letterSpacing: 0.5,
      },
      body2: {
        fontSize: 13.4,
        lineHeight: 1.5,
        letterSpacing: 0.25
      },
      caption: {
        fontSize: 12,
      },
      overline: {
        fontSize: 10
      },
      fontWeightBold: 600,
      fontFamily: "Poppins",
      button: {
        textTransform: "none",
      }
    }
  });
}

export function textFieldWithClr(passedInColor: string): any {
  return withStyles({
    root: {
      '& label.Mui-focused': {
        color: passedInColor,
      },
      '& .MuiInput-underline:not(.Mui-error):after': {
        borderBottomColor: passedInColor,
      },
      '& .MuiOutlinedInput-root': {
        // '& fieldset': {
        //   borderColor: passedInColor,
        // },
        '&:hover fieldset': {
          borderColor: passedInColor,
        },
        '&.Mui-focused fieldset': {
          borderColor: passedInColor,
        },
      },
    }
  })(TextField);
}

export function formControlWithClr(passedInColor: string): any {
  return withStyles({
    root: {
      '& label.Mui-focused': {
        color: passedInColor,
      },
      '& .MuiInput-underline:not(.Mui-error):after': {
        borderBottomColor: passedInColor,
      },
      '& .MuiInputLabel-outlined[data-shrink="false"]': {
        display: "none",
      },
      '& .MuiOutlinedInput-root': {
        // '& fieldset': {
        //   borderColor: passedInColor,
        // },
        '&:hover fieldset': {
          borderColor: passedInColor,
        },
        '&.Mui-focused fieldset': {
          borderColor: passedInColor,
        },
      },
    }
  })(FormControl);
}

export function switchWithClr(passedInColor: string): any {
  return withStyles({
    switchBase: {
      '&$checked': {
        color: passedInColor,
      },
      '&$checked + $track': {
        backgroundColor: passedInColor,
      },
    },
    checked: {},
    track: {
      backgroundColor: passedInColor
    },
  })(Switch);
}

export function radioWithClr(passedInColor: string): any {
  return withStyles({
    root: {
      color: passedInColor,
      '&$checked': {
        color: passedInColor,
      },
    },
    checked: {},
  })(Radio);
}

export function checkboxWithClr(passedInColor: string): any {
  return withStyles({
    root: {
      color: passedInColor,
      '&$checked': {
        color: passedInColor,
      },
    },
    checked: {
      color: passedInColor,
    },
  })(Checkbox);
}

export const CallToActionButton = styled(Button)({
  paddingTop: "0.75rem",
  paddingBottom: "0.75rem",
  paddingLeft: "1.5rem",
  paddingRight: "1.5rem",
  fontSize: "1.11rem",
  fontWeight: 600,
});

export const CallToActionOrangeButton = styled(CallToActionButton)({
  background: "hsl(35, 93%, 58%)",
  fontSize: 16,
  color: clrWhite,
  '&:hover': {
    background: "hsl(35, 93%, 55%)"
  }
});

export const StyledNegativeButton = styled(Button)({
  color: "white",
  backgroundColor: clrRed,
  "&:hover": {
    backgroundColor: "hsl(335, 82%, 49%)",
    borderColor: clrRed,
    // Reset on touch devices, it doesn't add specificity
    "@media (hover: none)": {
      backgroundColor: clrRed
    }
  }
});

export const StyledNegativeIconButton = styled(IconButton)({
  color: clrRed,
  "&:hover": {
    backgroundColor: "hsla(335, 82%, 54%, 0.05)",
  }
});

export const StyledNegativeOutlinedButton = styled(Button)(({theme}) => ({
  color: theme.palette.type === DARK_THEME ? clrIceBlue : clrGrey,
  borderColor: clrRed,
  "&:hover": {
    backgroundColor: theme.palette.type === DARK_THEME ? "hsla(335, 82%, 54%, 0.1)" : "hsla(335, 82%, 54%, 0.05)",
    borderColor: clrRed,
  }
}));

export const offBrandDarkGreenClr = "hsl(181, 35%, 27%)";
export const StyledOffBrandGreenButton = styled(Button)({
  color: "white",
  backgroundColor: offBrandDarkGreenClr,
  "&:hover": {
    color: "hsl(181, 35%, 24%)",
    backgroundColor: "hsla(345, 6%, 13%, 0.05)",
    borderColor: "hsl(181, 35%, 24%)",
    border: "2.5px solid",
    padding: "3.5px 13.5px",
  }
});

export const StyledOutlinedPrimaryGreenButton = styled(Button)(({theme}) => ({
  color: theme.palette.type === DARK_THEME ? clrIceBlue : clrGrey,
  borderColor: clrPrimaryGreen,
  "&:hover": {
    borderColor: clrPrimaryGreen,
  },
}));

export const StyledOutlinedBlueButton = styled(Button)(({theme}) => ({
  color: theme.palette.type === DARK_THEME ? clrIceBlue : clrGrey,
  borderColor: clrSecondaryBlue,
  "&:hover": {
    borderColor: clrSecondaryBlue,
  },
}));

export const StyledOutlinedGreyButton = styled(Button)(({theme}) => ({
  color: clrGrey,
  borderColor: clrGrey,
  "&:hover": {
    borderColor: clrGrey,
  },
}));

export const StyledBlueButton = styled(Button)({
  color: "white",
  backgroundColor: clrSecondaryBlue,
  "&:hover": {
    backgroundColor: "hsl(220, 83%, 47%)",
    // Reset on touch devices, it doesn't add specificity
    "@media (hover: none)": {
      backgroundColor: clrSecondaryBlue
    }
  }
});

export const CloseTooltip = withStyles({
  tooltipPlacementTop: {
    margin: "6px 0",
  },
  tooltipPlacementBottom: {
    margin: "10px 0",
  },
})(Tooltip);

export const CloseBottomTooltip = withStyles({
  tooltipPlacementBottom: {
    margin: "10px 0",
  },
})(Tooltip);

export const useRadioStyles = makeStyles({
  smallRadio: {
    "& .MuiSvgIcon-root": {
      fontSize: "1rem",
    },
    padding: "4px",
  },
  mediumRadio: {
    "& .MuiSvgIcon-root": {
      fontSize: "1.35rem",
    },
    padding: "6px",
  },
  growLabel: {
    "& .MuiFormControlLabel-label": {
      flexGrow: 1,
    },
  }
});

