import React, {FunctionComponent, useEffect} from "react";
import MessageBox from "../MessageBox/MessageBox";
import HelpEmail from "../HelpEmail/HelpEmail";
import "./ErrorBoundary.scss";
import Body1 from "../Typography/Body1";

const ErrorMessage: FunctionComponent<{}> = () => {

  useEffect(() => {

  }, []);

  return (<div className="padding-inline-self">
    <MessageBox text={"Hmm... Something went wrong"} error/>
    <div className="MuiPaper-rounded padding-self error-boundary-container">
      <Body1>Please refresh the page to try again or email us at <HelpEmail/></Body1>
    </div>
  </div>);
}

export default ErrorMessage;