import React, {FunctionComponent, useEffect} from "react";
import './MessageBox.scss';
import {ReactComponent as Stroke} from "../../assets/stroke.svg";
import Hyphenated from 'react-hyphen';
import Body1 from "../Typography/Body1";

const strokeAndFontColors = [
  "#EA2B7B",
  "#39B54A",
  "#02B3B1",
  "#26A7DF",
  "#F7A430",
  "#C46CF5",
]

export type MessageBoxProps = {
  error?: boolean,
  text: string | JSX.Element,
}

export const messageBoxId = "message-box-id";

const MessageBox: FunctionComponent<MessageBoxProps> = (messageBoxProps) => {
  const {
    text,
    error
  } = messageBoxProps;

  useEffect(() => {
    let pickedColor
    if (error) {
      pickedColor = strokeAndFontColors[0];
    } else {
      pickedColor = strokeAndFontColors[Math.floor(Math.random() * strokeAndFontColors.length)]
    }

    const strokePaths = document.querySelectorAll("g#stroke > path");
    strokePaths.forEach(strokePath => {
      strokePath.setAttribute("fill", pickedColor);
    })
  }, [])

  return (
    <div id={messageBoxId} className="message-box-medium-container center-text">
      <Hyphenated>
        <div className="message-box-medium-text auto-inline-self max-small-text-width">
          <p id="base-box" className="message-box-medium-text bold base-box">
            <Stroke className="stroke"/>
            {text}
          </p>
        </div>
      </Hyphenated>
    </div>
  )

}

export default MessageBox;