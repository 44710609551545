import validate from "validate.js";
import {fetchPostResponse} from "../../../utils/fetchUtils";
import {AxiosError, AxiosResponse} from "axios";

export type VerifySignupFieldsDtos = {
  fieldName: string,
  fieldValue: string
}

export function callSignupVerifyLambda(body: VerifySignupFieldsDtos, errorText: string): Promise<any> {
  // @ts-ignore
  return new validate.Promise(function (resolve, reject) {
    fetchPostResponse<any>('access/verify', body)
      .catch((error: AxiosError<any>) => {
        if (error.status && error.status > 499 || error.response === undefined) {
          throw Error(JSON.stringify(error.response))
        }

        return error.response
      }).then((res: AxiosResponse<any>) => {
      if (res.status === 200) {
        resolve();
        return
      }

      resolve(errorText)
      return res
    })
  });
}