import React, {CSSProperties, FunctionComponent, useEffect, useMemo, useRef, useState} from "react";
import {displayElementById} from "../../utils/displayUtils";
import CircularProgress from "@material-ui/core/CircularProgress";
import Zoom from "@material-ui/core/Zoom";
import OurModal from "../OurModal/OurModal";
import "./ImgWithLoading.scss";
import uniqueId from "lodash/uniqueId";
import HighlightOffTwoToneIcon from '@material-ui/icons/HighlightOffTwoTone';
import {createStyles, makeStyles} from "@material-ui/core/styles";
import ErrorIcon from '@material-ui/icons/Error';
import Caption from "../Typography/Caption";
import ImageGallery, {ReactImageGalleryItem} from 'react-image-gallery';
import "react-image-gallery/styles/scss/image-gallery.scss";
import {TransformComponent, TransformWrapper} from "react-zoom-pan-pinch";
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import {DARK_THEME} from "../../contexts/ThemePreferenceContext/ThemePreferenceProvider";
import {clrGrey} from "../../style/MaterialUIStyling";

type LoadingImgProps = {
  alt?: string,
  color?: string,
  dimension: string,
  extendContainerStyle?: CSSProperties,
  grow?: boolean,
  img: string | File,
  maxDimension?: boolean,
  onRemove?: (file: File | string) => void,
  overrideObjectFit?: "cover" | "contain" | "fill" | "none" | "scale-down",
  overrideOverflow?: string,
  otherImages?: (File | string)[] | readonly (string | File)[],
  showRemove?: boolean,
  currIndex?: number,
  zoomEnabled?: boolean,
  noModal?: boolean,
  extendImgStyle?: CSSProperties,
  onError?: () => void,
}

const useIconStyles = makeStyles((theme) =>
  createStyles({
    deleteIcon: {
      fontSize: "2rem",
      margin: "-1.2rem",
      position: "absolute",
      right: "0",
      top: "0",
      cursor: "pointer",
      color: theme.palette.type === DARK_THEME ? clrGrey : "hsla(345, 6%, 13%, 0.26)",
      "&:hover": {
        color: theme.palette.type === DARK_THEME ? "hsl(0, 0%, 32%)" : "hsla(345, 6%, 13%, 0.4)",
      }
    },
    closeIcon: {
      width: "calc(35px + 1vw)",
      height: "calc(35px + 1vw)",
      position: "absolute",
      right: "1vw",
      top: "1vh",
    }
  }));

/*
 The reason why I change the display on img element along with the doing the zoom, is because once its starts loading,
 the html element containing it will exist as an empty element in the dom with the dimensions passed in the dom.
 The reason its empty is because the zoom isn't in yet, otherwise it'll show as loading gradually.
 */
const ImgWithLoading: FunctionComponent<LoadingImgProps> = (loadingImgProps) => {
  const {
    alt,
    color,
    currIndex,
    dimension,
    extendContainerStyle,
    grow,
    img,
    maxDimension,
    onRemove,
    overrideObjectFit,
    overrideOverflow,
    otherImages,
    showRemove,
    zoomEnabled,
    noModal,
    extendImgStyle,
    onError,
  } = loadingImgProps;

  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const elementId = useRef<string>(uniqueId("img-with-loading-"));
  const imgId = useRef<string>(uniqueId("loading-img-"));
  const loadingIconRef = useRef(null);
  const errorId = useRef<string>(uniqueId("loading-error-"));
  const imgSrc = useMemo(() => typeof img === "string" ? img : URL.createObjectURL(img), [img]);
  const [isPanningEnabled, setIsPanningEnabled] = useState<boolean>(false);
  const galleryImages = otherImages?.map((otherImg) => {
    return {
      original: typeof otherImg === "string" ? otherImg : URL.createObjectURL(otherImg),
    }
  }) as ReadonlyArray<ReactImageGalleryItem>;
  const [imgHeight, setImgHeight] = useState<number>(0);
  const [imgWidth, setImgWidth] = useState<number>(0);

  const iconStyles = useIconStyles();

  useEffect(() => {
    if (loadingIconRef.current) {
      // @ts-ignore
      loadingIconRef.current.style.display = "flex";
    }

    const currImg = new Image();
    currImg.crossOrigin = "anonymous";
    currImg.src = imgSrc;
    currImg.onload = () => {
      setImgHeight(currImg.height);
      setImgWidth(currImg.width);
    }
  }, []);

  function displayImg() {
    if (loadingIconRef.current) {
      // @ts-ignore
      loadingIconRef.current.style.display = "none";
    }
    displayElementById(imgId.current);
    setIsLoaded(true);
  }

  function handleLoadError() {
    if (loadingIconRef.current) {
      // @ts-ignore
      loadingIconRef.current.style.display = "none";
    }
    setShowError(true);
    if (onError) {
      onError();
    }
  }

  function closeModal() {
    setIsModalOpen(false);
  }

  function handleCloseIconClick() {
    if (onRemove) {
      onRemove(img);
    }
  }

  function getImageWithZoom(src: string, className: string) {
    return <TransformWrapper centerZoomedOut={true}
                             panning={{
                               disabled: !isPanningEnabled,
                             }}
                             onZoom={(ref, event) => {
                               if (ref.state.scale > 1) {
                                 setIsPanningEnabled(true);
                               } else {
                                 setIsPanningEnabled(false);
                               }
                             }}
    >
      <TransformComponent>
        {getImageWithNoZoom(src, className)}
      </TransformComponent>
    </TransformWrapper>
  }

  function getImageWithNoZoom(src: string, className: string) {
    return <img alt=""
                className={className}
                id={imgId.current}
                crossOrigin="anonymous"
                key={src}
                src={src}
    />
  }

  function getImageElement(src: string, className: string) {
    if (zoomEnabled) {
      return getImageWithZoom(src, className);
    }
    return getImageWithNoZoom(src, className);
  }

  return (
    <>
      <div ref={loadingIconRef}
           style={{
             minWidth: "calc(" + dimension + "/2)",
             minHeight: "calc(" + dimension + "/2)",
             display: "none"
           }}>
        <CircularProgress style={{margin: "auto", color: color ? color : undefined}}/>
      </div>
      <div style={{
        position: "relative",
        margin: showRemove ? "0.75rem" : "none",
        ...extendContainerStyle,
      }}>
        <Zoom in={isLoaded}>
          <>
            {showError ?
              <div id={errorId.current} className="flex-col-gap clr-red padding-inline-self padding-block-self loading-error">
                <ErrorIcon/>
                <Caption>Failed to load</Caption>
              </div>
              :
              <img alt={alt}
                   className={noModal ? "" : ("clickable-img " + (grow ? "clickable-img-grow" : ""))}
                   id={imgId.current}
                   key={imgSrc}
                   crossOrigin="anonymous"
                   onLoad={displayImg}
                   onError={handleLoadError}
                   src={imgSrc}
                   style={{
                     objectFit: overrideObjectFit ? overrideObjectFit : "contain",
                     overflow: overrideOverflow ? overrideOverflow : "hidden",
                     width: "100%",
                     height: "100%",
                     maxWidth: maxDimension ? "inherit" : dimension,
                     maxHeight: maxDimension ? "inherit" : dimension,
                     display: "none",
                     verticalAlign: "middle",
                     ...extendImgStyle,
                   }}
                   onClick={() => {
                     if (!noModal) {
                       setIsModalOpen(true)
                     }
                   }}
              />}
            {showRemove ? <HighlightOffTwoToneIcon id="img-close-icon-id" className={iconStyles.deleteIcon} onClick={handleCloseIconClick}/> : undefined}
          </>
        </Zoom>
      </div>
      <OurModal parentToggle={isModalOpen}
                setParentClose={closeModal}
      >
        <CloseRoundedIcon className={iconStyles.closeIcon + " image-gallery-icon"} onClick={closeModal}/>
        {otherImages && otherImages.length > 1 && (imgHeight > 60 && imgWidth > 130) ?
          <ImageGallery items={galleryImages}
                        showThumbnails={false}
                        showFullscreenButton={false}
                        showPlayButton={false}
                        showBullets={true}
                        startIndex={currIndex}
                        renderItem={(item) => {
                          return <div style={{width: '100%'}}>
                            {getImageElement(item.original, "image-gallery-image")}
                          </div>
                        }}
          /> :
          getImageElement(imgSrc, "modal-img")}
      </OurModal>
    </>
  )
}


export default ImgWithLoading;