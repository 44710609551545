import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import {Amplify} from 'aws-amplify';
import validate from "validate.js";
import {callSignupVerifyLambda, VerifySignupFieldsDtos} from "./routes/signUp/Validators/AsyncSignupValidator";

const amplifyConfig = require(process.env['REACT_APP_AMPLIFY_CONFIG_FILE'] as string).default;
Amplify.configure(amplifyConfig)

ReactDOM.render(
  <App/>,
  document.getElementById("root")
);

serviceWorkerRegistration.register();

// Shared validators are initialized this high so that the lazy loading or compilation order doesn't cause them to be undefined

const usernameAlreadyExists = "^Username already exists";
const emailAlreadyExists = "^Email already exists";

validate.validators.validateUniqueEmail = function (email: string) {
  if (email.length === 0) {
    return
  }

  return callSignupVerifyLambda(getVerifySignupFieldsDto("email", email), emailAlreadyExists);
}

validate.validators.validateUniqueUserName = function (userName: string) {
  if (userName.length === 0) {
    return
  }

  return callSignupVerifyLambda(getVerifySignupFieldsDto("username", userName), usernameAlreadyExists);
}


function getVerifySignupFieldsDto(fieldName: string, accessKey: string): VerifySignupFieldsDtos {
  return {
    fieldName: fieldName,
    fieldValue: accessKey
  }
}