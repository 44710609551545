import React, {createContext, FunctionComponent, useCallback, useContext, useEffect, useState} from "react";
import {fetchGetBody} from "../../utils/fetchUtils";
import {useCookies} from "react-cookie";
import {Auth} from "aws-amplify";
import {useLocation, useNavigate} from "react-router-dom";
import {CognitoUser} from "amazon-cognito-identity-js";
import {isSessionNew, setSession} from "../../utils/SessionTrackerUtils";
import {reloadIfUnAuthorized, RethrownError} from "../../utils/erroryLoggingUtils";

enum SignupCookiesNames {
  BOARDING_STATUS_CHECKED = "onboarded-",
}

export type OnboardingStatus = {
  emailVerified: boolean | undefined,
  commissionTypesFilled: boolean | undefined,
  paymentOnboarded: boolean | undefined,
  forceCheckOnboardingStatus: () => void,
}

const {BOARDING_STATUS_CHECKED} = SignupCookiesNames;

const initOnBoardingStatus = {
  emailVerified: undefined,
  commissionTypesFilled: undefined,
  paymentOnboarded: undefined,
  forceCheckOnboardingStatus: () => {
  }
};

const OnBoardingContext = createContext<OnboardingStatus>(initOnBoardingStatus);

const useOnBoardingContext = () => useContext(OnBoardingContext);

const OnboardingProvider: FunctionComponent<{}> = (props) => {
  const [currUser, setCurrUser] = useState<CognitoUser | undefined>(undefined);
  const [onboardingStatus, setOnboardingStatus] = useState<OnboardingStatus>(initOnBoardingStatus);
  const [onboardingCookieName, setOnboardingCookieName] = useState<string>(BOARDING_STATUS_CHECKED);
  const [cookies, setCookies] = useCookies([onboardingCookieName]);
  const navigate = useNavigate();
  const location = useLocation();
  const checkOnBoardingStatus = useCallback(() => {
    Auth.currentAuthenticatedUser({bypassCache: false})
      .then((user: CognitoUser) => {
        fetchGetBody<OnboardingStatus>("onboarding/status", user.getSignInUserSession()?.getIdToken().getJwtToken())
          .then(onboardingStatus => {
            // @ts-ignore
            setOnboardingStatus({...onboardingStatus, emailVerified: user.attributes.email_verified, forceCheckOnboardingStatus: checkOnBoardingStatus});
            return onboardingStatus;
          })
          .then(onboardingStatus => {
            if (!onboardingStatus.paymentOnboarded) {
              const pathname = location.pathname;
              if (!pathname.includes("profile") && !pathname.includes("paypal-onboarded") && !pathname.includes("faq") && isSessionNew()) {
                setSession();
                navigate("/profile")
              }
            } else {
              setCookies(onboardingCookieName, "true");
            }
          }).catch(err => {
          throw new RethrownError("Error while fetching onboarding status", err);
        })
      })
      .catch(() => {
      })
  }, [onboardingCookieName])

  useEffect(() => {
    Auth.currentAuthenticatedUser({bypassCache: false})
      .then((currUser) => {
          setCurrUser(currUser);
          setOnboardingCookieName(BOARDING_STATUS_CHECKED + currUser.username.substring(0, 12));
        }
      ).catch(() => {
    })
  }, [])

  useEffect(() => {
    if (currUser && onboardingCookieName !== BOARDING_STATUS_CHECKED) {
      if (cookies[onboardingCookieName] === "false" || cookies[onboardingCookieName] === undefined) {
        checkOnBoardingStatus();
      } else {
        Auth.currentAuthenticatedUser({bypassCache: false}).then((user: CognitoUser) => {
          setOnboardingStatus({
            commissionTypesFilled: true,
            paymentOnboarded: true,
            // @ts-ignore
            emailVerified: user.attributes.email_verified,
            forceCheckOnboardingStatus: checkOnBoardingStatus
          });
        }).catch(() => {

        })
      }
    }
  }, [currUser, onboardingCookieName])


  return (<OnBoardingContext.Provider value={onboardingStatus}>
    {props.children}
  </OnBoardingContext.Provider>)
}

export {OnboardingProvider, useOnBoardingContext};
