import React, {FunctionComponent, useEffect} from 'react';
import HeaderBar from './components/Header/HeaderBar';
import {BrowserRouter} from "react-router-dom";
import {logErrorToBackend, RethrownError} from "./utils/erroryLoggingUtils";
import {getCookieConsentValue} from "react-cookie-consent";
import {initGA} from "./utils/GAUtils";
import {removePersonalCookie} from "./contexts/CookieSettingsContext/CookieSettingsContext";
import {getMetaTags, siteImgPreview} from "./utils/metaTagsUtils";
import {OnboardingProvider} from "./contexts/SignupOnboardingContext/OnboardingProvider";
import {optInMixPanel} from "./utils/MixPanelUtils";
import mixpanel from "mixpanel-browser";
import {ThemePreferenceProvider} from "./contexts/ThemePreferenceContext/ThemePreferenceProvider";
import {ConfirmEmailProvider} from "./contexts/ConfirmEmailContext/ConfirmEmailContext";
import {UserContextProvider} from "./contexts/UserContext/UserContext";

if (process.env.NODE_ENV === 'production') {
  console.log = function () {
  };
}

mixpanel.init(process.env['REACT_APP_MIXPANEL_TOKEN'] as string);
export const siteDescription = "A digital art commissioning platform where creators are fairly paid, organized, and environmentally conscious.";

const App: FunctionComponent<{}> = () => {

  useEffect(() => {
    if (getCookieConsentValue() === "true") {
      initGA();
      optInMixPanel();
    } else {
      // this is done here because, I believe, there is a delay of when Google's GA stops working
      // so doing this right after the button is clicked isn't working
      removePersonalCookie();
    }
  }, [])

  const baseTitle = "Artistree | Digital Art Commissions Platform";


  return (
    <div>
      {/* @ts-ignore Server Component */}
      <React.StrictMode>
        {getMetaTags({
          description: siteDescription,
          title: baseTitle,
          type: "website",
          url: window.location.origin,
          ...siteImgPreview,
        })}
        <ThemePreferenceProvider>
          <BrowserRouter>
            <UserContextProvider>
              <HeaderBar/>
            </UserContextProvider>
          </BrowserRouter>
        </ThemePreferenceProvider>
      </React.StrictMode>
    </div>
  )
}

export default App;

const globalPromiseRejectionHandler: (this: WindowEventHandlers, ev: PromiseRejectionEvent) => void = (event) => {
  const error = event.reason;
  // @ts-ignore
  const errorPathname = event.target?.location?.pathname;

  // @ts-ignore
  if (error.name === "RethrownError") {
    logErrorToBackend(error, {componentStack: error.original_error.stack}, "rethrow error uncaught promise at: " + window.location.origin + errorPathname);
    return;
  }

  logErrorToBackend(error, {componentStack: event.promise}, "uncaught promise at: " + window.location.origin + errorPathname);
}

// window.onunhandledrejection = globalPromiseRejectionHandler;
// window.onerror = (message, source, lineno, colno, error) => {
//   logErrorToBackend(error, {message, source, lineno, colno}, "uncaught error at: " + window.location.origin + window.location.pathname);
// }
