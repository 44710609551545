import React, {CSSProperties, FunctionComponent, useEffect, useState} from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fade from "@material-ui/core/Fade";
import {PropTypes} from "@material-ui/core";
import OurCircularProgressBar, {OurCircularProgressBarProps} from "../OurCircularProgressBar/OurCircularProgressBar";
import {DisplayOptions, HIDE, SHOW} from "../../utils/displayUtils";

export type ActionButtonProps = {
  action: () => Promise<any>,
  className?: string,
  circularProgressBarProps?: OurCircularProgressBarProps,
  color: PropTypes.Color,
  disabled?: boolean,
  skipFinally?: boolean,
  text: string,
  variant: 'text' | 'outlined' | 'contained',
  buttonComponent?: React.FunctionComponent,
  overrideProgressColor?: string,
  style?: CSSProperties,
  autoFocus?: boolean,
}

const AsyncActionButton: FunctionComponent<ActionButtonProps> = (actionButtonProps) => {
  const {
    action,
    className,
    circularProgressBarProps,
    color,
    disabled,
    text,
    skipFinally,
    variant,
    buttonComponent,
    overrideProgressColor,
    style,
    autoFocus,
  } = actionButtonProps;
  const {currentCount, displayed: circularProgressDisplayed, bottomText, topText, totalCount, percentage} = circularProgressBarProps || {};

  const [switchToLoading, setSwitchToLoading] = useState<boolean>(false);
  const [actionButtonDisplay, setActionButtonDisplay] = useState<DisplayOptions>(SHOW)
  const [circularIconDisplay, setCircularIconDisplay] = useState<DisplayOptions>(HIDE);
  const [circularProgressDisplay, setCircularProgressDisplay] = useState<DisplayOptions>(HIDE);

  const ButtonElement = buttonComponent ? buttonComponent : Button;

  useEffect(() => {

  }, [])

  useEffect(() => {
    if (circularProgressDisplayed) {
      setCircularProgressDisplay(SHOW);
      setCircularIconDisplay(HIDE);
      setActionButtonDisplay(HIDE);
    } else {
      setCircularIconDisplay(HIDE);
      setCircularProgressDisplay(HIDE);
      setActionButtonDisplay(SHOW);
    }
  }, [circularProgressDisplayed]);

  function resetTheState() {
    setCircularIconDisplay(HIDE);
    setActionButtonDisplay(SHOW);
    if (circularProgressDisplayed) {
      setCircularProgressDisplay(HIDE);
    }
    setSwitchToLoading(false);
  }

  function handleClick() {
    setActionButtonDisplay(HIDE);
    setCircularIconDisplay(SHOW);
    setSwitchToLoading(true);

    action()
      .catch(error => {
        resetTheState();
        throw error;
      }).finally(() => {
      if (!skipFinally) {
        resetTheState();
      }
    });
  }

  return (<div className={className}
               style={style}>
    <Fade in={switchToLoading && !circularProgressDisplayed}>
      <CircularProgress style={{display: circularIconDisplay, color: overrideProgressColor || undefined}}/>
    </Fade>
    <Fade in={!switchToLoading && !circularProgressDisplayed}>
      <ButtonElement color={color}
                     autoFocus={autoFocus}
                     disabled={disabled === undefined ? false : !disabled}
                     style={{display: actionButtonDisplay}}
                     onClick={handleClick}
                     variant={variant}
      >
        {text}
      </ButtonElement>
    </Fade>
    <Fade in={circularProgressDisplayed}>
      <div style={{display: circularProgressDisplay}}>
        <OurCircularProgressBar currentCount={currentCount!}
                                bottomText={bottomText!}
                                displayed={true}
                                topText={topText!}
                                totalCount={totalCount!}
                                percentage={percentage}
                                overrideColor={overrideProgressColor}
        />
      </div>
    </Fade>
  </div>);
}

export default AsyncActionButton;